import Styled from 'styled-components';
import { mainFontColor } from '../../../../styles/colors';
import { mainFont } from '../../../../styles/global';
const ScStageHeader = Styled.div`
  max-width: 600px;

  .panelHeader {
    &-iconWrapper {
      background-color: ${mainFontColor};
      width: 40px;
      height: 40px;
      border-radius: 2px;

    }

    &-content {
      margin-left: 12px;
      margin-right: 8px;
      color: ${mainFontColor};
    }
  }

  .settings-splashScreen {
    .panelHeader-iconWrapper {
      position: relative;
      svg {
        position: absolute;
        left: 10px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .panelHeader {
      flex-basis: 100%;
      background-color: ${mainFontColor};
      align-items: center;

      &-iconWrapper {
        width: 70px;
        height: 70px;
        border-right: 1px solid #D8DAE9;
      }

      &-title, &-subtitle {
        color: #fff;
        font-weight: 500;
        font-family: ${mainFont};
      }

      &-subtitle {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;
export default ScStageHeader;
