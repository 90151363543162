import Styled, { css } from 'styled-components';
import { mainFontColor } from '../../../../styles/colors';
export const ScTextInput = Styled.div`
// TODO: @Buri, Should edit dark theme styles and implement right panel text inputs with new style.

${({ theme })=>theme === 'dark' ? css`
    & > div {
      border-color: #303339;
      border-radius: 4px;
      + .limit {
        position: absolute;
        bottom: 10px;
        right: 8px;
        color: #a9a9af;
        font-size: 12px;
      }
    }

    input {
      background-color: var(--jfv-panel-right-item-background);
      font-size: 16px;
      color: #fff;
      padding: 12px;

      &::placeholder {
        color: #AEB0B8;
      }
    }
  ` : css`
    & > div {
      border-color: #C8CEED;
      border-radius: 4px;
    }

    input {
      background-color: #ffffff;
      color: ${mainFontColor};
      font-size: 16px;
      padding: 15px;
      border-radius: 4px;
    }
`}
`;
