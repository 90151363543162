function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { t } from '@jotforminc/translation';
import * as COMPONENT_NAMES from '../../../constants/componentNames';
import { isYes } from '../../../utils';
const toggleClassname = `pt-6 pb-5 px-4 w-full mb-0 flex justify-between items-center p-${COMPONENT_NAMES.TOGGLE}`;
const textInputClassname = `pt-6 pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`;
const getValidationDropdownOpions = ()=>[
        {
            text: t('None'),
            value: 'None'
        },
        {
            text: t('Alphabetic'),
            value: 'Alphabetic'
        },
        {
            text: t('Alphanumeric'),
            value: 'Alphanumeric'
        },
        {
            text: t('Currency'),
            value: 'Currency'
        },
        {
            text: t('Cyrillic'),
            value: 'Cyrillic'
        },
        {
            text: t('Email'),
            value: 'Email'
        },
        {
            text: t('Numeric'),
            value: 'Numeric'
        },
        {
            text: t('URL'),
            value: 'Url'
        }
    ];
const getPredefinedOptDropdownOptions = ()=>[
        {
            text: t('None'),
            value: 'None'
        },
        {
            text: t('Gender'),
            value: 'Gender'
        },
        {
            text: t('Days'),
            value: 'Days'
        },
        {
            text: t('Months'),
            value: 'Months'
        }
    ];
const getInputRangeLimitTypeDropdownOptions = ()=>[
        {
            text: t('Words'),
            value: 'Words'
        },
        {
            text: t('Letters'),
            value: 'Letters'
        }
    ];
const dropdownElement = (label, description, selectedOptionValues, options)=>({
        type: COMPONENT_NAMES.NEWDROPDOWN,
        labelProps: {
            label: label,
            description: description,
            class: `pt-6 pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.NEWDROPDOWN}`
        },
        componentProps: {
            selectedOptionValues: selectedOptionValues,
            options: options,
            dropdownInputClass: 'portal-new-dropdown-button w-full color-white mt-5 ',
            dropdownWrapperClass: 'portal-new-dropdown-wrapper',
            dropdownTheme: 'dark'
        }
    });
const toggleElement = (label, description, isSelected, isReadOnly = false, valueTrue, valueFalse)=>({
        type: COMPONENT_NAMES.TOGGLE,
        labelProps: {
            label: label,
            description: description,
            class: toggleClassname
        },
        componentProps: {
            isSelected: isSelected,
            readOnly: isReadOnly,
            valueTrue,
            valueFalse
        }
    });
const requiredElement = (isSelected, isReadOnly = false)=>toggleElement(t('Required'), t('Prevent submission if this field is empty'), isSelected, isReadOnly);
const hideFieldElement = (isSelected, isReadOnly = false)=>toggleElement(t('Hide Field'), '', isSelected, isReadOnly);
const textInputElement = (label, defaultValue, isSelected = true, hintOn = '', className = textInputClassname)=>({
        type: COMPONENT_NAMES.TEXTINPUT,
        labelProps: {
            label: label,
            class: className
        },
        componentProps: {
            defaultValue: defaultValue,
            hintOn: hintOn
        },
        condition: ()=>isSelected
    });
const fieldLabelElement = (defaultValue)=>textInputElement(t('Field Label'), defaultValue);
const inputMaskElements = (description, isSelected, toggledInputDefVal = '', inputMaskPropValue, valueTrue, valueFalse)=>({
        inputMask: toggleElement(t('Input Mask'), description, isSelected, false, valueTrue, valueFalse),
        [inputMaskPropValue]: textInputElement('', toggledInputDefVal, isSelected, '', `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`)
    });
const textAreaElement = (label, description, defaultValue, onBlur, disabled = false)=>({
        type: COMPONENT_NAMES.TEXTAREA,
        labelProps: {
            label: label,
            description: description,
            class: 'w-11/12 m-4'
        },
        componentProps: {
            defaultValue: defaultValue,
            onBlur: onBlur,
            inputClass: 'w-full h-28 my-2 p-2 bg-gray-500',
            disabled: disabled
        }
    });
const inputRangeElement = (minLimit, maxLimit, handlePropChange, isSelected, minKey = 'minsize', maxKey = 'maxsize')=>({
        type: COMPONENT_NAMES.INPUT_RANGE,
        noLabel: true,
        componentProps: {
            currencyInfo: '',
            minLimit: minLimit,
            maxLimit: maxLimit,
            minKey,
            maxKey,
            onCustomChange: handlePropChange,
            inputClass: 'pt-0 pb-5 px-4 w-full mb-0'
        },
        condition: ()=>isSelected
    });
const inputRangeWithTypeElement = (entryLimit, entryLimitMin, handlePropChange, isSelected, limitDescription = '', limitTypeOptions = getInputRangeLimitTypeDropdownOptions())=>({
        type: COMPONENT_NAMES.INPUT_RANGE_WITH_TYPE,
        noLabel: true,
        componentProps: {
            currencyInfo: '',
            entryLimit,
            entryLimitMin,
            limitTypeOptions: limitTypeOptions,
            limitDescription: limitDescription,
            onCustomChange: handlePropChange,
            inputClass: 'pt-0 pb-5 px-4 w-full mb-0'
        },
        condition: ()=>isSelected
    });
const dividerElement = {
    type: COMPONENT_NAMES.DIVIDER,
    labelProps: {
        class: 'lineHr block border-transparent w-full m-0'
    }
};
// eslint-disable-next-line complexity
export const getElementList = (props, handlePropChange, requiredElementsQuestionIds = [])=>{
    switch(props.type){
        case 'control_phone':
            return _object_spread_props(_object_spread({
                text: fieldLabelElement(props.text),
                divider: dividerElement,
                countryCode: toggleElement(t('Country Code'), t('Include a number field for country code'), isYes(props.countryCode)),
                divider2: dividerElement
            }, inputMaskElements(t('Enter # for numbers'), isYes(props.inputMask), props.inputMaskValue, 'inputMaskValue', 'enable', 'disable')), {
                divider3: dividerElement,
                required: requiredElement(isYes(props.required)),
                divider4: dividerElement,
                hidden: hideFieldElement(isYes(props.hidden))
            });
        case 'control_textbox':
            return _object_spread_props(_object_spread({
                text: fieldLabelElement(props.text),
                divider: dividerElement,
                validation: dropdownElement(t('Validation'), t('Require entries to match a certain format'), props.validation === 'Fill Mask' ? '' : props.validation, getValidationDropdownOpions()),
                divider2: dividerElement,
                characterLimit: toggleElement(t('Character Limit'), t('Limit the number of characters allowed for this field'), isYes(props.characterLimit) || !!props.minsize || !!props.maxsize),
                characterLimitRange: inputRangeElement(props.minsize, props.maxsize, handlePropChange, isYes(props.characterLimit) || !!props.minsize || !!props.maxsize),
                divider3: dividerElement
            }, inputMaskElements(`<p class="inputMask-text">${t('Restrict users to match the format you specify.')}</p>
          <p class="inputMask-text">${t('Use <strong>@</strong> symbol to mask letters, <strong>#</strong> for numbers and <strong>*</strong> for both.')}</p>
          <p class="inputMask-text">
            ${t('<a href="https://www.jotform.com/help/381-Getting-Started-with-the-basics-of-Input-Masking" target="_blank" class="color-blue-400">Learn more about Input Masks</a>.')}
          </p>`, isYes(props.inputMask) || !!props.inputTextMask, props.inputTextMask, 'inputTextMask')), {
                divider4: dividerElement,
                required: requiredElement(isYes(props.required)),
                divider5: dividerElement,
                hidden: hideFieldElement(isYes(props.hidden))
            });
        case 'control_address':
            return {
                text: fieldLabelElement(props.text),
                divider: dividerElement,
                subfieldOptions: {
                    type: COMPONENT_NAMES.LABELVALUETABLE,
                    labelProps: {
                        label: t('Subfield Options'),
                        description: t('Select which fields to include on your form and edit their sublabels'),
                        class: 'pt-6 pb-5 px-4 block w-full'
                    },
                    componentProps: {
                        subfieldOrder: props.subfieldOrder,
                        subfields: props.subfields,
                        states: props.states,
                        sublabels: props.sublabels,
                        onCellChange: (value)=>handlePropChange({
                                key: 'sublabels',
                                value
                            }),
                        onCheckbox: (value)=>handlePropChange({
                                key: 'subfields',
                                value
                            }),
                        onSort: (value)=>handlePropChange({
                                key: 'subfieldOrder',
                                value
                            }),
                        disabled: requiredElementsQuestionIds.includes(props.qid)
                    }
                },
                divider2: dividerElement,
                states: {
                    type: COMPONENT_NAMES.RADIOGROUP,
                    labelProps: {
                        label: t('State Options'),
                        description: t('Give your users a text box or predefined options'),
                        class: 'pt-6 pb-5 px-4 block w-full'
                    },
                    componentProps: {
                        defaultValue: props.states,
                        options: [
                            {
                                key: 'textbox',
                                value: 'textbox',
                                text: 'Text Box'
                            },
                            {
                                key: 'americanStates',
                                value: 'americanStates',
                                text: 'American States'
                            }
                        ]
                    },
                    condition: ()=>props.subfields.split('|').includes('state')
                },
                divider3: dividerElement,
                required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
                divider4: dividerElement,
                hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
            };
        case 'control_fullname':
            {
                return {
                    text: fieldLabelElement(props.text),
                    divider: dividerElement,
                    middle: toggleElement(t('Middle Name'), t('Let users enter a middle name'), isYes(props.middle)),
                    divider2: dividerElement,
                    prefix: toggleElement(t('Prefix'), t('Let users enter a title before their name'), isYes(props.prefix)),
                    divider3: dividerElement,
                    suffix: toggleElement(t('Suffix'), t('Let users enter a title after their name'), isYes(props.suffix)),
                    divider4: dividerElement,
                    required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
                    divider5: dividerElement,
                    hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
                };
            }
        case 'control_email':
            {
                return {
                    text: fieldLabelElement(props.text),
                    divider: dividerElement,
                    entryLimits: toggleElement(t('Entry Limits'), t('Limit the maximum number of characters'), props.entryLimits ? isYes(props.entryLimits) : !!props.maxsize, false, 'Yes', 'No'),
                    maxsize: {
                        type: COMPONENT_NAMES.SUFFIXINPUT,
                        noLabel: true,
                        componentProps: {
                            type: 'number',
                            value: props.maxsize,
                            suffix: 'CHARS',
                            parentClass: 'suffixInput'
                        },
                        condition: ()=>{
                            return props.entryLimits ? isYes(props.entryLimits) : !!props.maxsize;
                        }
                    },
                    divider2: dividerElement,
                    disallowFree: toggleElement(t('Disallow Free Addresses'), t('Don’t accept free email addresses'), isYes(props.disallowFree)),
                    divider3: dividerElement,
                    required: requiredElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.required), requiredElementsQuestionIds.includes(props.qid)),
                    divider4: dividerElement,
                    hidden: hideFieldElement(requiredElementsQuestionIds.includes(props.qid) ? true : isYes(props.hidden), requiredElementsQuestionIds.includes(props.qid))
                };
            }
        case 'control_radio':
            {
                return {
                    text: fieldLabelElement(props.text),
                    divider: dividerElement,
                    options: textAreaElement(t('Options'), t('Give options for users to select from. Enter each option on a new line.'), props.options.replaceAll('|', '\n'), (e)=>handlePropChange({
                            key: 'options',
                            value: e.target.value.replaceAll('\n', '|')
                        }), !!props.special && props.special !== 'None'),
                    divider2: dividerElement,
                    special: dropdownElement(t('Predefined Options'), t('Select a ready-made list of options (e.g., days, months, etc.)'), props.special, getPredefinedOptDropdownOptions()),
                    divider3: dividerElement,
                    allowOther: toggleElement(t('Display Other Option'), t('Allow users to enter text when their selection is not available.'), isYes(props.allowOther)),
                    otherText: textInputElement('', props.otherText, isYes(props.allowOther), t('Other Text'), `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`),
                    divider4: dividerElement,
                    required: requiredElement(isYes(props.required)),
                    divider5: dividerElement,
                    hidden: hideFieldElement(isYes(props.hidden))
                };
            }
        case 'control_textarea':
            {
                const isEntryLimitSelected = props.entryLimit !== 'None-0' || isYes(props.entryLimitToggle);
                return {
                    text: fieldLabelElement(props.text),
                    divider: dividerElement,
                    entryLimitToggle: toggleElement(t('Entry Limits'), t('Limit the minimum or maximum amount of text allowed'), isEntryLimitSelected),
                    entryLimitRangeWithType: inputRangeWithTypeElement(props.entryLimit, props.entryLimitMin, handlePropChange, isEntryLimitSelected, t('Limit Type'), getInputRangeLimitTypeDropdownOptions()),
                    divider2: dividerElement,
                    required: requiredElement(isYes(props.required)),
                    divider3: dividerElement,
                    hidden: hideFieldElement(isYes(props.hidden))
                };
            }
        case 'control_checkbox':
            {
                const isEntryLimitSelected = isYes(props.entryLimit) || !!props.minSelection || !!props.maxSelection;
                return {
                    text: fieldLabelElement(props.text),
                    divider: dividerElement,
                    options: textAreaElement(t('Options'), t('Give options for users to select from. Enter each option on a new line.'), props.options.replaceAll('|', '\n'), (e)=>handlePropChange({
                            key: 'options',
                            value: e.target.value.replaceAll('\n', '|')
                        }), !!props.special && props.special !== 'None'),
                    divider2: dividerElement,
                    special: dropdownElement(t('Predefined Options'), t('Select a ready-made list of options (e.g., days, months, etc.)'), props.special, getPredefinedOptDropdownOptions()),
                    divider3: dividerElement,
                    allowOther: toggleElement(t('Display Other Option'), t('Allow users to enter text when their selection is not available.'), isYes(props.allowOther)),
                    otherText: textInputElement('', props.otherText, isYes(props.allowOther), t('Other Text'), `pb-5 px-4 w-full block mb-0 p-${COMPONENT_NAMES.TEXTINPUT}`),
                    divider4: dividerElement,
                    entryLimit: toggleElement(t('Entry Limits'), t('Limit the minimum or maximum amount of text allowed'), isEntryLimitSelected),
                    entryLimitRange: inputRangeElement(props.minSelection, props.maxSelection, handlePropChange, isEntryLimitSelected, 'minSelection', 'maxSelection'),
                    divider5: dividerElement,
                    required: requiredElement(isYes(props.required)),
                    divider6: dividerElement,
                    hidden: hideFieldElement(isYes(props.hidden))
                };
            }
        default:
            return {};
    }
};
