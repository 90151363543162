/* eslint-disable max-len */ import Styled from 'styled-components';
const onSmallMobile = 'screen and (max-height: 600px)';
export const ScPassword = Styled.div`
  * { box-sizing: border-box; }

  font-size: 16px;
  max-width: 340px;

  .xcl {
    &-field-wr {
      position: relative;

      &.forPassword {
        .xcl-lbl-err {
          span { display: block; margin-bottom: 4px; }
          span:before { display: inline-block; content: '-'; margin-right: 3px; }
        }
      }
    }

    &-togglePass-btn {
      position: absolute;
      top: 34px;
      right: 7px;
      background: none;
      border: 0;
      padding: 0;
      z-index: 2;
      border-radius: 0;
      border: 0;
      background-color: transparent;
      color: inherit;
      text-align: center;
      height: 30px;
      width: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      transition: all .2s;

      #signupPage & {
        .tiny-mobile & {
          top: 30px;
          @media ${onSmallMobile}{
            top: 25px;
            right: 1px;
          }
        }
      }

      #myaccount & {
        .tiny-mobile & {
          top: 30px;
          @media ${onSmallMobile}{
            top: 25px;
            right: 1px;
          }
        }
      }

      .primaryHeader & {
        right: 5px;
        width: 30px;
        height: 26px;
      }

      &:focus-visible {
        outline: none;
        border-color: #799AF8;
        box-shadow: 0 0 0 3px #d3ddfd;
        background-color: #eaf0ff;
        .xcl-togglePass-icon {
          svg{
            path{
              fill: #2e69ff;
            }
          }
        }
      }

      &:hover{
        background-color: #eaf0ff;

        .xcl-togglePass-icon {
          svg{
            path{
              fill: #2e69ff;
            }
          }

          .xcl-togglePass-tooltip {
            opacity: 1;
            margin-top: -24px;
          }
        }

      }

    }
    &-togglePass-icon { display: block; color: rgb(136, 148, 171); }
    &-togglePass-icon svg { width: 20px; height:20px; display: block!important; }
    &-togglePass-tooltip {
      background-color: #2b3245;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: right;
      color: #c3cad8;
      white-space: nowrap;
      position: absolute;
      right: 3px;
      top: 0;
      min-height: 24px;
      border-radius: 2px;
      padding: 4px;
      opacity: 0;
      pointer-events: none;
      margin-top: -22px;
      transition: all .2s;
      &:after{
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 6px;
        bottom: -6px;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12px' height='6.1px' viewBox='0 0 12 6.1' style='enable-background:new 0 0 12 6.1;' xml:space='preserve'%3E%3Cpath style='fill:%232B3245' d='M1.6,0.8l3.6,4.8c0.4,0.5,1.2,0.5,1.6,0l3.6-4.8C10.8,0.3,11.4,0,12,0H0C0.6,0,1.2,0.3,1.6,0.8z'/%3E%3C/svg%3E%0A");
        background-position: bottom center;
        background-repeat: no-repeat;
      }
    }
    &-inp {
      font-size: 16px;
      display: block;
      width: 100%;
      margin-top: 8px;
      position: relative;
      background-color: #fff;
      z-index: 2;

      &::-webkit-credentials-auto-fill-button,
      &::-webkit-contacts-auto-fill-button {
        background-color: #8894AB;
      }

      &.forPassword{
        padding-right: 44px;

        html[dir="rtl"] & {
          padding-left: 44px;
          padding-right: 12px;
        }
      }

      &:focus {
        outline: none;
        border-color: #799AF8;
        box-shadow: 0 0 0 3px #d3ddfd;
      }
      &::placeholder { color: #b9c5cb; }
      &.errored { border-color: #D4372C; box-shadow: 0px 0px 0px 3px #F6D7D5; }
      &.successed { border-color: #4caf50; box-shadow: 0 0 0px 3px #c8e6c9; }
    }

    &-lbl-err {
      background-color: #D4372C;
      color: #fff;
      display: inline-block;
      margin-top: 0;
      font-size: 12px;
      text-align: left;
      padding: 0 8px;
      border-radius: 2px;
      z-index: 1;
      line-height: 0;
      transition: all .2s;
      overflow: hidden;

      &.isVisible { 
        padding: 4px 8px;
        margin-top: 8px;
        line-height: 16px;
      }
      &.forTerms { bottom: 5px; border-radius: 0 4px 4px 4px; padding: 4px 8px; }
    }
  }

  @keyframes fieldsSmoothEnter {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .hPass-reqs {
    color: #0A1551;
    font-size: .875rem;
    text-align: left;
    line-height: 1.5em;
    margin-bottom: 2em;
    transition: max-height .3s ease-in-out;

    p { 
      margin: 0.5em 0 1em;
      color: #333;
     }
    ul {
      margin: 0; padding: 0 0 0 1.25em; list-style-type: disc;

      &.has-icon {
        padding: 0;
        list-style-type: none;
      }
    }
    li {
      opacity: 0;
      animation: fieldsSmoothEnter .3s ease-in-out forwards;
      display: flex;
      align-items: center;
    }
    li + li { margin-top: .25em; }

    .hPass-reqs-info-text {
      display: flex;
      align-items: center;
      color: #6C73A8;
      gap: 0.5rem;
    }

    .hPass-reqs-info-icon {
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
    }
  }

  .hPass-req {
    span {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      html[dir="rtl"] & {
        margin-left: 8px;
        margin-right: 0;
      }
    }

    &.isGood, &.isBad {
      svg {
        fill: #fff;
        width: 12px;
        height: 12px;
      }
    }

    &.isGood {
      color: #2B3245;
      span {
        background-color: #54B45C;
      }
    }

    &.isBad {
      color: #2B3245;
      span {
        background-color: #FB4958;
      }
    }

    &.isNeutral {
      span {
        background-color: #C8CEED;
      }
    }
  }

  .forgot-password-ui {
    margin-left: auto;
    display: block;
    margin-top: 8px;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 14px;
    color: #4277FF;
    text-decoration: underline;
    cursor: pointer;
  }
`;
