import Styled from 'styled-components';
import { mainFontColor } from '../../../../../styles/colors';
const ScAppIconPreview = Styled.div`
  display: block;
  width: 100%;
  white-space: nowrap;
  background-color: #F3F3FE;

  height: unset;
  overflow: unset;
  .appIconPreviewWrapper {
    background: url("https://cdn.jotfor.ms/assets/img/portal/mobileViewDefaultBgB21.png") no-repeat center / cover;
  }

  .appIconPreview {
    display: flex;
    flex-direction: column;
    height: calc(100% - 17px);

    .iconBlock {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      &:last-child {
        margin-bottom: 15px;
      }
    }

    .icon {
      width: 55px;
      height: 55px;
      background-color: rgba(36, 43, 62, 0.15);
      border-radius: 10px;
      margin: 0 11px;
    }
  }

  & > div {
    background-color: #F3F3FE;
    overflow-y: auto;
  }

  .appIconPreviewWrapper {
    width: 100%;
    height: 100%;

    .statusBar {
      height: 17px;
      background-color:  ${mainFontColor};
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 9px;
      position: relative;
      padding: 0 13px;

      &-operator {
        position: absolute;
        left: 13px;
        display: flex;
        align-items: center;

        & > svg {
          margin-right: 3px;
        }
      }

      &-time {
        flex: 1 1 100%;
        text-align: center;
      }

      &-battery-container {
        position: absolute;
        right: 13px;
        display: flex;
        align-items: center;

        & > svg {
          margin-left: 3px;
        }
      }
    }

    .appIcon {
      cursor: pointer;

      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 12px;
        margin: 0 -10px;
      }
    }
  }
`;
export default ScAppIconPreview;
