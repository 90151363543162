export const inputStyle = (type = 'text')=>{
    const baseStyle = `bg-white color-navy-700 border text-md min-w-44 p-2 text-left border-navy-100 mt-3 hover:border-navy-200 hover:border-opacity-80
  radius focus:outline focus:outline-blue-200 sm:mt-0`;
    let additionalStyles = '';
    if (type === 'text') {
        additionalStyles = 'h-10';
    }
    if (type === 'textarea') {
        additionalStyles = 'h-16';
    }
    return `${baseStyle} ${additionalStyles}`;
};
