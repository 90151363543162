import Styled from 'styled-components';
import { primaryRed, rightPanelItemBorderColor } from '../../../../../../styles/colors';
export const ScPopoverIconSelector = Styled.div`
  min-height: 410px;
  
  .icon-selector-wizard {
    background-color: ${rightPanelItemBorderColor};
    border-radius: 4px;
    margin: 0 1em;

    .iconSelectorPanel {
      border-radius: 4px;
    }

    .remove-icon-button {
      background-color: #212326;
      margin-left: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
      &:hover {
        background-color: ${primaryRed};
      }
    }
  }

  [data-popper-placement^='top'] [data-popper-arrow] {
    bottom: -4px;
  }
  [data-popper-placement^='bottom'] [data-popper-arrow] {
    top: -4px;
  }
  [data-popper-placement^='left'] [data-popper-arrow] {
    right: -4px;
  }
  [data-popper-placement^='right'] [data-popper-arrow] {
    left: -4px;
  }

  [data-popper-arrow] {
    transform: translate(29px, 0px) !important;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: ${rightPanelItemBorderColor};
      transform: rotate(45deg);
    }
  }
`;
