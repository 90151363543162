import Styled from 'styled-components';
const ScMultipleImagePreview = Styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;

  & > div {
    position: relative;
    width: calc(33.333% - 4px);
    cursor: ${({ sortable })=>sortable === true ? 'move' : 'initial'};

    &:hover {
      .rightSideWrapper {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
`;
export default ScMultipleImagePreview;
