/* eslint-disable max-len */ import styled from 'styled-components';
export const ColumnPickerWrapper = styled.div`
  .tagify, .tagify:hover {
    border: 1px solid #292D3C;
    background-color: #434A60;
  }
  div.jfTagInput .tags-input .tagify__tag > div {
    font-size: 12px;
  }
  .tagify__tag > div:before {
    box-shadow: 0 0 0 16px #E3E5F5 inset;
  }
  .tagify__tag__removeBtn:hover + div:before {
    box-shadow: 0 0 0 16px #DADEF3 inset !important;
  }
  .tagify__tag-text {
    text-transform: capitalize;
  }
  .tagify__tag__removeBtn:hover + div > span {
    opacity: 1;
  }
  div.jfTagInput .tags-input .tagify--mix .tagify__input {
    padding: 6px 12px;
    &::before {
      color: #AEB0B8;
    }
  }
  .jfTagInput {
    position: relative;
  }
  div.jfTagInput .tags-input .tagify__tag__removeBtn,
  div.jfTagInput .tags-input .tagify__tag:hover .tagify__tag__removeBtn {
    opacity: 1 !important;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6px;
    position: initial;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.74582 1.07931C5.97363 0.851503 5.97363 0.482156 5.74582 0.25435C5.51802 0.0265446 5.14867 0.0265446 4.92087 0.25435L3.00001 2.17521L1.07916 0.25435C0.85135 0.0265446 0.482004 0.0265446 0.254198 0.25435C0.026392 0.482156 0.026392 0.851503 0.254198 1.07931L2.17505 3.00016L0.254198 4.92102C0.026392 5.14882 0.026392 5.51817 0.254198 5.74598C0.482004 5.97378 0.85135 5.97378 1.07916 5.74598L3.00001 3.82512L4.92087 5.74598C5.14867 5.97378 5.51802 5.97378 5.74582 5.74598C5.97363 5.51817 5.97363 5.14882 5.74582 4.92102L3.82497 3.00016L5.74582 1.07931Z' fill='%230A1551'/%3E%3C/svg%3E%0A");
    background-color: rgb(10 21 81 / 10%);
  }
  div.jfTagInput .tags-input .tagify__tag-text,
  .tagify__tag > div > * {
    overflow: initial;
    text-overflow: initial;
  }
  .formFields {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: -32px;
    li {
      &:not(:last-child) {
        border-bottom: 1px solid #4B5260;
      }
      .formFields-option {
        max-width: 366px;
      }
      .formFields-option:hover {
        background-color: rgba(85, 95, 110, 0.5);
      }
    }
    .formFields-button {
      position: static;
      background: none;
      color: #37C768;
      padding-right: 4px;
    }
    .formFields-popover {
      min-width: 364px;
      background-color: #2F333A;
    }
    .formFields-wrapper ul {
      margin: 0;
    }
    .formFields-option-content {
      padding: 12px;
    }
    .formFields-select {
      padding: 0;
      max-width: 364px;
    }
    .formFields-header {
      background-color: transparent;
      color: #A0A6B6;
      margin: 0;
    }
    .formFields-option-text,
    .formFields-option-text-seperator {
      color: #fff;
      text-transform: capitalize;
      margin: 0;
    }
    .formFields-icon-wrapper {
      background-color: transparent;
      &:before {
        content: "";
        display: flex;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjY2Njc1IDMuMzMyNjhDOC42NjY3NSAyLjk2NDQ5IDguMzY4MjcgMi42NjYwMiA4LjAwMDA4IDIuNjY2MDJDNy42MzE4OSAyLjY2NjAyIDcuMzMzNDEgMi45NjQ0OSA3LjMzMzQxIDMuMzMyNjhWNy4zMzI2OEgzLjMzMzQxQzIuOTY1MjIgNy4zMzI2OCAyLjY2Njc1IDcuNjMxMTYgMi42NjY3NSA3Ljk5OTM1QzIuNjY2NzUgOC4zNjc1NCAyLjk2NTIyIDguNjY2MDIgMy4zMzM0MSA4LjY2NjAySDcuMzMzNDFWMTIuNjY2QzcuMzMzNDEgMTMuMDM0MiA3LjYzMTg5IDEzLjMzMjcgOC4wMDAwOCAxMy4zMzI3QzguMzY4MjcgMTMuMzMyNyA4LjY2Njc1IDEzLjAzNDIgOC42NjY3NSAxMi42NjZWOC42NjYwMkgxMi42NjY3QzEzLjAzNDkgOC42NjYwMiAxMy4zMzM0IDguMzY3NTQgMTMuMzMzNCA3Ljk5OTM1QzEzLjMzMzQgNy42MzExNiAxMy4wMzQ5IDcuMzMyNjggMTIuNjY2NyA3LjMzMjY4SDguNjY2NzVWMy4zMzI2OFoiIGZpbGw9IiMzN0M3NjgiLz4KPC9zdmc+Cg==");
      }
      .formFields-icon {
          display: none;
      }
    }
  }
`;
