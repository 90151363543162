import Styled from 'styled-components';
import { primaryAccentDefault } from '../../../../styles/colors';
export const ScItemStylerWrapper = Styled.div`
  border: 1px solid #2F333A;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px #0a0b0d52, 0px 2px 4px 0px #0a0b0d0a;

  .tabContent {
    background-color: var(--jfv-panel-dark-gray);
    height: 100%;
    border-radius: 0 0 4px 4px;

    .color-palette {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 12px 16px;
    }
  }

  .tabMenu .tabMenu-inner-link:after {
    background-color: ${primaryAccentDefault};
  }

  @media screen and (max-width: 480px) {
    .tabContent .color-palette {
      justify-content: center;
      padding: 16px;
    }
  }
`;
export const ScColorPaletteButton = Styled.div`
  button {
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin: 8px;
    padding: 0;
    height: 50px;
    width: 140px;
    ${({ itemBgColor, itemBgColorEnd })=>itemBgColor && itemBgColorEnd && `background: linear-gradient(${itemBgColor}, ${itemBgColorEnd})`};
    ${({ itemBgColor, itemBgColorEnd })=>(itemBgColor === itemBgColorEnd || !itemBgColorEnd) && `background: ${itemBgColor}`};
    background-color: ${({ itemBgColor })=>itemBgColor};
    color: ${({ itemFontColor })=>itemFontColor};
    border: ${({ itemBorderColor })=>`1px solid ${itemBorderColor}`};

    &.selected {
      border-radius: 2px;
      outline: #fff solid 1px;
    }
  }

  @media screen and (max-width: 480px) {
    button {
      max-width: 96px;
      margin: 0;
    }

    &:nth-child(2n+1) {
      margin: 8px 8px 0 0;
    }

    &:nth-child(2n) {
      margin: 8px 0 0 8px;
    }

    &:nth-child(-n+2) {
      margin-top: 0;
    }
  }
`;
export const ScCustomColorPalette = Styled.div`
  display: block;

  label {
    color: #fff;
  }

  input {
    background-color: #555F6E;
    color: #fff;
    border-radius: 2px;
    padding: 12px;
    line-height: 1.125;
    font-size: 1em;
    box-shadow: none;

    /* TODO: @Buri Which color? */
    &:focus, &:active, &:hover { outline: 0; border-color: #4573e3; cursor: pointer; }
    &:focus { box-shadow: 0 0 0 3px rgba(69, 115, 227, 0.3); }

    @media screen and (max-width: 480px) { padding-right: calc(36px + 1rem); }
  }
`;
