import { takeEvery, select, put, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import isEmpty from 'lodash/isEmpty';
import SELECTORS from './selectors';
import * as ACTION_CREATORS from './actionCreators';
import keyboard from '../keyboard';
import { checkMacOSx, getTeamID } from '../utils';
import { getUndoRedoBindings } from '../modules/Builder/constants/undoRedo';
import { teamLog } from '../modules/api';
const keyboardEventChannel = (keys, preventDefault = false)=>eventChannel((emitter)=>{
        const handlePress = (e)=>{
            var _document_activeElement;
            if (!(document.activeElement instanceof global.HTMLInputElement) && !(document.activeElement instanceof global.HTMLTextAreaElement) && !((_document_activeElement = document.activeElement) === null || _document_activeElement === void 0 ? void 0 : _document_activeElement.hasAttribute('contentEditable'))) {
                if (preventDefault) e.preventDefault();
                emitter(e);
            }
        };
        keyboard.bind(keys, handlePress);
        return ()=>{
            keyboard.unbind(keys, handlePress);
        };
    });
const globalEventChannel = (event)=>eventChannel((emitter)=>{
        global.addEventListener(event, emitter);
        return ()=>{
            global.removeEventListener(event, emitter);
        };
    });
function* watchDeletePressed() {
    const isUIBlocked = yield select(SELECTORS.isUIBlockedSelector);
    const isBuildTab = yield select(SELECTORS.getIsBuildTab);
    const selectedPortalItem = yield select(SELECTORS.getSelectedPortalItem);
    const activeModal = yield select(SELECTORS.getActiveModal);
    const isModalOpen = !!activeModal && activeModal.name !== '';
    if (selectedPortalItem && !isUIBlocked && isBuildTab && !isModalOpen) {
        yield put(ACTION_CREATORS.removeSelectedItemsAction());
    }
}
function* watchSelectAllPressed() {
    const isUIBlocked = yield select(SELECTORS.isUIBlockedSelector);
    const isBuildTab = yield select(SELECTORS.getIsBuildTab);
    if (!isUIBlocked && isBuildTab) {
        yield put(ACTION_CREATORS.trackEventAction({
            action: 'selectAllViaKeyboard'
        }));
        yield put(ACTION_CREATORS.selectAllItemsAction());
    }
}
function* watchUndoPressed() {
    const isUIBlocked = yield select(SELECTORS.isUIBlockedSelector);
    const hasUndo = yield select(SELECTORS.hasUndoSelector);
    if (!isUIBlocked && hasUndo) {
        yield put(ACTION_CREATORS.trackEventAction({
            action: 'undoRequestViaKeyboard'
        }));
        yield put(ACTION_CREATORS.undoAction());
    }
}
function* watchRedoPressed() {
    const isUIBlocked = yield select(SELECTORS.isUIBlockedSelector);
    const hasRedo = yield select(SELECTORS.hasRedoSelector);
    if (!isUIBlocked && hasRedo) {
        yield put(ACTION_CREATORS.trackEventAction({
            action: 'redoRequestViaKeyboard'
        }));
        yield put(ACTION_CREATORS.redoAction());
    }
}
function* watchOnStageClick(e) {
    const excludedPaymentSelectors = `#headlessui-portal-root,
    #detach-connection-dialog, .magnet-colorpicker, #gateway-connection-modal-container,
    #connection-switch-dialog
  `;
    const excludedSelectors = `.select-formCont li, [data-uikit-modal-container=true], .rightPanelContainer, .toolbox,
  .appHeader, .appLogo-cont, .addButton, .mce-container, .mce-reset, .mce-ico, .mce-window-head, .leftPanelContainer,
  .subheaderActions, .multipleSelectionSubHeader, .itemListWrapper, .pageInlineEdit, .dashedLineButton, .forAddIcon, .payment-modal-container, .portal-new-dropdown-wrapper, 
  #navigation-settings-button, .previewToggleWrapper, label.togglePreview, #CookieBannerNotice, ${excludedPaymentSelectors}`;
    const excludedElements = excludedSelectors ? global.document.querySelectorAll(excludedSelectors) : [];
    const isExcludedElement = [
        ...excludedElements
    ].find((el)=>el.contains(e.target));
    if (!isExcludedElement) {
        yield put(ACTION_CREATORS.onStageClick());
    }
}
function* watchOnTeamBeforeUnload() {
    const appID = yield select(SELECTORS.getAppID);
    const editingResourceInfo = yield select(SELECTORS.getEditingResourceInfo);
    if (isEmpty(editingResourceInfo)) yield call(teamLog, appID, 'editingReleased');
}
function* watchArrowUpDownPressed(e) {
    var _document, _activeElement_className;
    const activeElement = (_document = document) === null || _document === void 0 ? void 0 : _document.activeElement;
    if (activeElement === null || activeElement === void 0 ? void 0 : (_activeElement_className = activeElement.className) === null || _activeElement_className === void 0 ? void 0 : _activeElement_className.includes('builderItem')) {
        switch(e.keyCode){
            case 40:
                var _activeElement_nextSibling;
                activeElement === null || activeElement === void 0 ? void 0 : (_activeElement_nextSibling = activeElement.nextSibling) === null || _activeElement_nextSibling === void 0 ? void 0 : _activeElement_nextSibling.focus();
                break;
            case 38:
                var _activeElement_previousSibling;
                activeElement === null || activeElement === void 0 ? void 0 : (_activeElement_previousSibling = activeElement.previousSibling) === null || _activeElement_previousSibling === void 0 ? void 0 : _activeElement_previousSibling.focus();
                break;
            default:
                break;
        }
    }
    yield;
}
export default function* eventsFlow() {
    const isBuilder = yield select(SELECTORS.getIsBuilder);
    if (isBuilder) {
        const [undoBinding, redoBinding] = getUndoRedoBindings();
        yield takeEvery(keyboardEventChannel([
            'delete',
            'del'
        ]), watchDeletePressed);
        yield takeEvery(keyboardEventChannel([
            `${checkMacOSx() ? 'command' : 'ctrl'} + a`
        ], true), watchSelectAllPressed);
        yield takeEvery(keyboardEventChannel([
            undoBinding
        ], true), watchUndoPressed);
        yield takeEvery(keyboardEventChannel([
            redoBinding
        ], true), watchRedoPressed);
        yield takeEvery(keyboardEventChannel([
            'up',
            'down'
        ]), watchArrowUpDownPressed);
        yield takeEvery(globalEventChannel('mousedown'), watchOnStageClick);
        if (getTeamID()) {
            yield takeEvery(globalEventChannel('beforeunload'), watchOnTeamBeforeUnload);
        }
    }
}
