import Styled from 'styled-components';
export const ScDropdown = Styled.div`
  position: relative;
  border-radius: 4px;
  background-color: var(--jfv-panel-right-item-background);
  .rp-dropdown {
    display: inline-flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    height: 44px;
    padding-left: 12px;
    width: calc(100% - 40px);
    > div:first-child {
      overflow: hidden;
    }
    span {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    }
    input {
      padding: 0;
    }
    .char-limit {
      position: absolute;
      color: #a9a9af;
      font-size: 12px;
      right: 45px;
      bottom: 2px;
    }
  }
  .rp-dropdown-icon-wrapper {
    position: absolute;
    align-items: center;
    border-left: 1px solid var(--jfv-panel-darkest-gray);
    display: inline-flex;
    height: 100%;
    justify-content: center;
    width: 40px;
    margin-left: auto;
    right: 0;
    svg {
      color: var(--jfv-panel-darkest-gray);
    }
  }
  .dropdown-option {
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 6px 16px 6px 28px;
    &.table-name {
      font-size: 12px;
      color: #A0A6B6;
      :hover {
        background-color: inherit;
      }
    }
    .isActive,
    :hover {
      background-color: rgba(85, 95, 110, 0.5);
    }
  }
  .dropdown-option-wrapper > div {
    position: absolute;
    left: 0;
    right: 0;
    border-color: rgb(36, 43, 62);
    background-color: #2F333A;
    box-shadow: 0 2px 8px rgba(86, 70, 50, 0.15);
    border-radius: 4px;
    z-index: 2;
    width: 100%;
    padding-bottom: 16px;
    .option-text {
      display: flex;
    }
  }

  .selected-option-icon {
    margin-left: auto;
    color: #09F;
  }

  .no-options-text-wrapper {
    display: flex;
    justify-content: center;
    padding: 16px 16px;
  }
`;
