import styled from 'styled-components';
export const ScCircularProgressBar = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;

    .circular-progress-bar {
        width: 80px;
        height: 80px;
    }
`;
