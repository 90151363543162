import Styled from 'styled-components';
import { Tooltip as UIKitTooltip } from '@jotforminc/uikit';
import { rightPanelItemBorderColor } from '../../styles/colors';
export const ScUIKitTooltip = Styled(UIKitTooltip)`
font-size: .75rem;
line-height: normal;
padding: .5rem;
text-transform: lowercase;
border-radius: 2px;
color: #fff;
font-weight: 400;
background-color: ${rightPanelItemBorderColor};
text-align: right;
z-index: 2;

&:first-letter { text-transform: uppercase; }

&[data-popper-placement^='top']>[data-popper-arrow] {
  bottom: -4px;
}
&[data-popper-placement^='bottom']>[data-popper-arrow] {
  top: -4px;
}
&[data-popper-placement^='left']>[data-popper-arrow] {
  right: -4px;
}
&[data-popper-placement^='right']>[data-popper-arrow] {
  left: -4px;
}

`;
export const ScTooltipArrow = Styled.div`
// fix for tooltip arrow to point correct element
// related link for fix: https://github.com/floating-ui/react-popper/issues/364
width: 10px;
height: 10px;

&:before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: ${rightPanelItemBorderColor};
  transform: rotate(45deg);
}
`;
