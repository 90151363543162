/* eslint-disable max-len */ import Styled from 'styled-components';
export const ScCheckbox = Styled.div`
display: flex;

.jfCheckbox {
  &-wrapper:not(.isTransparent) {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #8D8FA6;
    background: unset;
    &.isChecked { border: unset; }
  }

  &-input {
    left: 0;
    top: 0;
    cursor: pointer;

    + .jfCheckbox-check {
      background: transparent !important; // stylelint-disable-line
      border-color: unset !important; // stylelint-disable-line
    }
  }

  &-checkmark {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-input:checked {
    & + .jfCheckbox-check {
      background-color: #4573E3 !important;
      border-radius: 0;

      .jfCheckbox-checkmark {
        width: 100%;
        height: 100%;
        transform: unset;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.58479 7.5h-.00117c-.18234 0-.34727-.07433-.46631-.19454L.146922 4.31475C.0557493 4.20267 0 4.05807 0 3.90012 0 3.53601.295006 3.241.659117 3.241c.161441 0 .308944.05807.423343.15389l2.50465 2.52265L8.86295.691057C8.982.573171 9.14634.5 9.32753.5c.36469 0 .66027.295587.66027.66028 0 .18351-.07491.34901-.1957.46864L4.04878 7.30836c-.11847.11847-.28223.19164-.46341.19164H3.5842h.00059z' fill='%23fff'/%3E%3C/svg%3E");        background-repeat: no-repeat;
        background-position: 50%;

        &:before, &:after {
          content: unset;
        }
      }
    }
  }
}
`;
