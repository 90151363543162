import Styled from 'styled-components';
import { primaryAccentDefault } from '../../../../styles/colors';
import { isNewPanel } from '../../../../utils';
const newPanel = isNewPanel();
export const ScToggleButtonGroupContainer = Styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .selectionItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    ${newPanel ? `
      background-color: var(--jfv-panel-right-item-background);
    ` : `
      background-color: #555F6E;
    `}
    border: 1px solid #303339;
    cursor: pointer;
    transition: background-color .3s ease;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: normal;
    text-transform: capitalize;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-left: transparent;
      border-radius: 0 4px 4px 0;
    }
    &:not(:first-child):not(:last-child) {
      border-left: transparent;
    }
  }

  [aria-selected=true] {
    background-color: ${primaryAccentDefault};
  }

  .textAlignmentItem {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    &-leftAlign, &-rightAlign, &-centerAlign {
      margin-left: 6px;
      font-weight: 500;

      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }
`;
