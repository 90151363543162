import Styled from 'styled-components';
import { mainFont } from '../../styles/global';
export const ScFileUpload = Styled.div`
width: 100%;
display: block;

.imageUpload {
  .tabMenu {
    display: none;
  }

  .tabContent {
    padding: 0;

    .cardNew {
      border: none;
      min-height: fit-content;

      &-leftSide {
        max-width: 140px;
        flex: none;

        img {
          width: 120px;
          min-height: 120px;
          max-height: 120px;
          object-fit: contain;
        }
      }

      &-rightSide {
        max-width: calc(100% - 140px);
        flex: none;

        .cardNew-content {
          white-space: nowrap;
          margin-top: 0;

          &-fileName {
            width: 100%;
            color: #fff;
          }

          &-fileSize {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.4)
          }

          &-button {
            color: #e39045;
          }
        }
      }
    }

    .appItem & {
      padding: 14px 28px;
    }
  }

  .fileUpload {
    color: #afc2da;
    background-color: transparent;
    color: #23283a;

    .appItem & {
      margin-top: 0;
    }
  }
}

.imageUpload.isTransparent {
  background-color: #fff9;

  .tabContent {
    .appItem & {
      padding: 0;
    }

    .fileUpload {
      color: #afc2da;
      background-color: transparent;
      color: #23283a;
      height: 150px;

      .appItem & {
        margin-top: 0;
      }

      &-verticalAlign {
        form#myForm {
          padding: 12px;
        }

        &.stage {
          .inlineUploadIcon {
            display: flex;
          }
        }

        &-text {
          font-size: 12px;
          user-select: none;

          &-loader {
            border: 3px solid #c3cad8;
            border-top: 3px solid #949eb2;
          }

          &-loading {
            font-size: 14px;
            font-family: ${mainFont};
          }
        }
      }
    }
  }
}
`;
