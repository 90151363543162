/* eslint-disable no-param-reassign */ import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { eventChannel } from 'redux-saga';
import { ITEM_PROP_TYPE_VERSION, ITEM_TYPES } from '../../constants/itemTypes';
import { CARD_ACTION_VALUES } from '../../modules/Builder/components/HomePage/RightPanel/constants';
export const shouldUpdatePropFromResponse = (type, updatingProps)=>{
    let shouldUpdate = false;
    switch(type){
        case ITEM_TYPES.HEADING:
        case ITEM_TYPES.WIDGET:
            updatingProps = _object_spread_props(_object_spread({}, updatingProps), {
                version: ITEM_PROP_TYPE_VERSION
            });
            break;
        case ITEM_TYPES.CARD_ITEM:
            {
                const { buttonValue = null, buttonRole = '' } = updatingProps;
                if (buttonRole === CARD_ACTION_VALUES.FORM && buttonValue) {
                    shouldUpdate = true;
                }
                break;
            }
        case ITEM_TYPES.BUTTON:
            if ('buttonValue' in updatingProps) {
                shouldUpdate = true;
            }
            break;
        default:
            break;
    }
    return shouldUpdate;
};
export const resultMutaters = (currentItem)=>({
        [ITEM_TYPES.CARD_ITEM]: (result)=>{
            const { formTitle, buttonRole, buttonValue, title } = result[currentItem === null || currentItem === void 0 ? void 0 : currentItem.id];
            return {
                [currentItem.id]: {
                    formTitle,
                    buttonRole,
                    buttonValue,
                    title
                }
            };
        }
    });
export const eventChannelRegistry = ()=>{
    const channels = [];
    const register = (fn)=>{
        const channel = eventChannel(fn);
        channels.push(channel);
        return channel;
    };
    const closeAll = ()=>{
        channels.forEach((channel)=>channel.close());
    };
    return {
        register,
        closeAll
    };
};
export const AiChangesdList = (messages = {}, changes = {})=>{
    const changesInPages = changes.create.pages;
    if (changesInPages.length === 0) return false;
    const formatString = (element)=>{
        const elementValue = element.type === 'WIDGET' ? element.widgetSlug : element.type;
        return elementValue.toLowerCase().split('_').join(' ');
    };
    const newMessages = [
        ...messages
    ];
    let lastMessage = _object_spread({}, messages[messages.length - 1]);
    let messageContent = lastMessage.content;
    messageContent += `
    <div class='mt-4 pl-1 text-sm flex flex-col gap-2'>
      <b class="color-navy-700">Created</b>
  `;
    changesInPages.forEach((change)=>{
        let messageItem = `<div class='text-sm flex flex-col gap-1'><b class="color-apps-default">${change.name}:</b>`;
        messageItem += '<ul class="color-navy-700 list-disc pl-1 flex flex-col gap-1">';
        change.elements.forEach((element)=>{
            messageItem += `<li class="list-inside text-capitalize">${formatString(element)}</li>`;
        });
        messageContent += `${messageItem}</ul></div>`;
    });
    messageContent += '</div>';
    lastMessage = _object_spread_props(_object_spread({}, lastMessage), {
        content: `${messageContent}`
    });
    newMessages[newMessages.length - 1] = lastMessage;
    return newMessages;
};
