import styled from 'styled-components';
export const ScListItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
export const ScSideElementContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin-left: ${(props)=>!props.isStartElement ? 'auto' : ''};
    margin-right: ${(props)=>props.isStartElement ? '16px' : ''};
`;
export const ScIconWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ bgColor })=>bgColor ? `background-color: ${bgColor};` : ''}
    ${({ bgColor })=>bgColor ? 'border-radius: 4px;' : ''}
`;
