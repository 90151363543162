function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { getEnv, isHIPAA, isEU, getActiveApp, getRevision } from '@jotforminc/router-bridge';
import trackingList from '@jotforminc/tracking-config';
import FullStoryManager from './FullStoryManager';
import SentryManager from './SentryManager';
class TrackingManager {
    enableSentry(config, forceEnable = false) {
        if (forceEnable || getEnv() === 'PRODUCTION' && !isHIPAA() && !isEU()) {
            try {
                var _trackingList_activeApp;
                const activeApp = getActiveApp();
                const doesSentryNameExist = (_trackingList_activeApp = trackingList[activeApp]) === null || _trackingList_activeApp === void 0 ? void 0 : _trackingList_activeApp.sentry.name;
                const appName = activeApp === 'listings' ? 'js-listings' : activeApp;
                this.sentry = new SentryManager(_object_spread({}, config, doesSentryNameExist && {
                    release: `${appName}@${getRevision()}`
                }));
                if (this.FS) {
                    this.sentry.enableFSPlugin(this.FS);
                }
            } catch (error) {
                console.log('Failed to initialize Sentry !');
            }
        }
    }
    enableFS(userVars = null) {
        if (getEnv() === 'PRODUCTION' && !isHIPAA() && !isEU()) {
            try {
                if (!this.FSisInitialized()) {
                    this.FS = new FullStoryManager({
                        orgId: 'K1ZZ',
                        debug: false
                    });
                }
                if (userVars) {
                    this.setUserVars(userVars);
                }
                if (this.sentry) {
                    this.sentry.enableFSPlugin(this.FS);
                }
            } catch (error) {
                console.log('Failed to initialize Fullstory !');
            }
        }
    }
    enableErrorTracking(appName, forceEnable = false, extraConfigs = {}) {
        var _trackingList_appName, _trackingList_appName1;
        const configs = {
            sentry: _object_spread({}, trackingList === null || trackingList === void 0 ? void 0 : (_trackingList_appName = trackingList[appName]) === null || _trackingList_appName === void 0 ? void 0 : _trackingList_appName.sentry, extraConfigs === null || extraConfigs === void 0 ? void 0 : extraConfigs.sentry),
            fullStory: _object_spread({}, trackingList === null || trackingList === void 0 ? void 0 : (_trackingList_appName1 = trackingList[appName]) === null || _trackingList_appName1 === void 0 ? void 0 : _trackingList_appName1.fullStory, extraConfigs === null || extraConfigs === void 0 ? void 0 : extraConfigs.fullStory)
        };
        if (!Object.keys(configs.sentry || {}).length && !Object.keys(configs.fullStory || {}).length) {
            console.log(`Error tracking config couldn't found for ${appName}`);
            return;
        }
        // eslint-disable-next-line no-restricted-syntax
        for(const _ref in configs){
            let trackingType = _ref;
            if (trackingType !== 'sentry' && trackingType !== 'fullStory') {
                continue;
            }
            const config = configs[trackingType];
            if (config === undefined) continue;
            const { enable } = config, rest = _object_without_properties(config, [
                "enable"
            ]);
            if (enable) {
                if (!this.initTypes[trackingType]) {
                    console.error(`Tracking type ${trackingType} couldn't found`);
                    continue;
                }
                this.initTypes[trackingType](_object_spread({}, rest), forceEnable);
            }
        }
    }
    captureException(error, extras) {
        if (this.sentry) {
            var _this_sentry;
            (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.captureException(error, extras);
        } else if (error instanceof Error) {
            console.log(`Sentry not initialized! \nIf it's initialized, it would capture exception for ${error}\nStack:${error.stack}`);
        } else {
            console.log(`Sentry not initialized! \nIf it's initialized, it would capture exception for ${error}`);
        }
    }
    captureMessage(message, extras) {
        if (this.sentry) {
            var _this_sentry;
            (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.captureMessage(message, extras);
        } else {
            console.log(`Sentry not initialized! \nIf it's initialized, it would capture message for ${message}`);
        }
    }
    configureScope(callback) {
        var _this_sentry;
        (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.configureScope(callback);
    }
    configureGlobalScope(callback) {
        var _this_sentry;
        (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.configureGlobalScope(callback);
    }
    captureEvent(event, hint) {
        var _this_sentry;
        (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.captureEvent(event, hint);
    }
    createReduxEnhancer(enhanceOptions) {
        var _this_sentry;
        return (_this_sentry = this.sentry) === null || _this_sentry === void 0 ? void 0 : _this_sentry.createReduxEnhancer(enhanceOptions);
    }
    setUserVars(userVars) {
        var _this_FS;
        (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.setUserVars(userVars);
    }
    FSisInitialized() {
        var _this_FS;
        return (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.isInitialized();
    }
    event(eventName, eventProperties) {
        var _this_FS;
        (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.event(eventName, eventProperties);
    }
    getCurrentSessionURL() {
        var _this_FS;
        return (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.getCurrentSessionURL();
    }
    identify(uid, customVars) {
        var _this_FS;
        (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.identify(uid, customVars);
    }
    shutdown() {
        var _this_FS;
        (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.shutdown();
    }
    restart() {
        var _this_FS;
        (_this_FS = this.FS) === null || _this_FS === void 0 ? void 0 : _this_FS.restart();
    }
    constructor(){
        _define_property(this, "sentry", void 0);
        _define_property(this, "sentryOptions", void 0);
        _define_property(this, "FS", void 0);
        _define_property(this, "fullstoryOptions", void 0);
        _define_property(this, "initTypes", {
            sentry: (config, forceEnable)=>this.enableSentry(_object_spread({}, config), forceEnable),
            fullStory: ()=>this.enableFS()
        });
    }
}
export default TrackingManager;
