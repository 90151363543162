import Styled, { css } from 'styled-components';
export const ScBadge = Styled.div`
  display: inline-block;

  .badge-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #78BB07;

  ${({ color })=>{
    return css`background-color: ${color};`;
}}

    svg {
      width: 19px;
      height: 19px;
    }
  }
`;
