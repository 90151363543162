/* eslint-disable max-len */ import Styled from 'styled-components';
const navyColor = '#091141';
export const ScRelatedCategories = Styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;
  margin: 0 0 30px;
  font-weight: 400;
  h3 {
    width: 100%;
    color: #4a4a4a;
    font-size: 26px;
    font-weight: 400;
    margin: 0 0 10px 0;
  }
  .similar-category {
    width: calc(100% + 20px);
    list-style: none;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -10px;
    padding: 0;
    flex-wrap: wrap;
    .similar-category-card-list {
      width: 33.333%;
      .similar-category-card {
        cursor: pointer;
        background-color: #f9f9f9;
        padding: 10px;
        border-radius: 4px;
        margin: 10px;
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .count {
          color: ${navyColor};
          font-size: 14px;
          margin: 6px 0 0;
          display: flex;
          align-items: center;
          svg {
            margin-inline-end: 5px;
          }
        }        
      }
      &:nth-child(1n) {
        .similar-category-card {
          background-color: #D0F0FD;
        }
      }
      &:nth-child(2n) {
        .similar-category-card {
          background-color: #FDDAF3;
        }
      }
      &:nth-child(3n) {
        .similar-category-card {
          background-color: #EDE8C3;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 980px) {
    .similar-category {
      .similar-category-card-list {
        width: 50%;
      }
    }
  } 
  
`;
