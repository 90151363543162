import Styled from 'styled-components';
import { rightPanelItemBorderColor, rightPanelItemBgColor, branding21MainBlue } from '../../../../styles/colors';
import { applyAlpha } from '../../../../utils/styleUtils';
export const ScResettableInput = Styled.div`
  .textInput {
    &-Wrapper {
      display: flex;
      font-size: 1em;
      border: 1px solid ${rightPanelItemBorderColor};
      border-radius: 2px;
      background-color: ${rightPanelItemBgColor};

      input {
        width: 100%;
        height: 100%;
        border: 0px;
        flex: 1 1 auto;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: #AEB0B8;
        }
      }

      & > button {
        flex: 1 0 auto;
        padding: 10px;
        border-left: 1px solid ${rightPanelItemBorderColor};
        background-color: ${rightPanelItemBgColor};

        & > svg {
          display: block;
          margin: auto;
        }
      }

      &:focus {
        box-shadow: 0 0 0 3px ${applyAlpha(branding21MainBlue, 0.3)};
      }
    }
  }
`;
