import { useCallback } from 'react';
import { Hooks } from '@jotforminc/uikit';
import { useDispatch, useSelector } from 'react-redux';
import SELECTORS from '../../store/selectors';
import { updateItemLoadingStatus } from '../../store/actionCreators';
import { handleImageWithDimensions } from '../image';
const useRpImageUploadManager = ({ value, onChange, imageUploadPopoverRef, defaultValue, targetRef, allowMultipleSelection, autoRetrieveDimensions, multipleFileUpload })=>{
    const dispatch = useDispatch();
    const selectedItemID = useSelector(SELECTORS.getSelectedPortalItem);
    const [isImageUploadVisible, setImageUploadVisible] = Hooks.useClickOutsideStateMouseDown(false, [
        imageUploadPopoverRef,
        targetRef
    ]);
    const updateImageWithDimensions = async (imageURL)=>{
        try {
            const { width, height, proportion } = await handleImageWithDimensions(imageURL);
            onChange({
                imageURL,
                width,
                height,
                proportion
            });
        } catch (error) {
            console.error('Error loading image dimensions:', error);
            onChange({
                imageURL
            });
        }
    };
    const buildImageListString = (newValue)=>{
        const constructedNewValue = Array.isArray(newValue) ? newValue.join('\n') : newValue;
        return `${value ? `${value}\n` : ''}${constructedNewValue}`;
    };
    const handleOnChange = useCallback((newValue)=>{
        if (!allowMultipleSelection) {
            return autoRetrieveDimensions ? updateImageWithDimensions(newValue) : onChange(newValue);
        }
        const newImageListString = buildImageListString(newValue);
        onChange(newImageListString);
        setImageUploadVisible(false);
    }, [
        value,
        allowMultipleSelection
    ]);
    const handleRemoveImage = ()=>{
        setImageUploadVisible(false);
        onChange(defaultValue);
    };
    const handleItemLoading = useCallback((isLoading)=>dispatch(updateItemLoadingStatus({
            [selectedItemID]: isLoading
        })), [
        dispatch,
        selectedItemID
    ]);
    const handleImageUpload = (val)=>multipleFileUpload ? handleOnChange(val.map((img)=>img.url).join('\n')) : handleOnChange(val.url);
    return {
        handleRemoveImage,
        handleItemLoading,
        handleImageUpload,
        isImageUploadVisible,
        setImageUploadVisible,
        handleOnChange
    };
};
export default useRpImageUploadManager;
