import Styled from 'styled-components';
export const ScPortalItem = Styled.div`
  font-family: "Circular", "Circular Std", "Circular Std Bold", "Circular Std Book", "Work Sans SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 9px 0 0;
  max-width: 100%;
`;
