import Styled from 'styled-components';
import { settingsBgColor } from '../../../../../../styles/colors';
export const ScRpMenuDeviceSection = Styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: ${settingsBgColor};
  height: 125px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 4px;

  .menuDevice {
    padding-top: 25px;
    position: relative;

    img {
      width: 270px;
      height: 540px;
    }
  }
`;
