import Styled from 'styled-components';
export const ScNotificationWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: auto;
  position: relative;
  border-radius: 8px;

  .preventedForms-icon-container {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 4px;
    padding: 10px;
    background-color: #FFEDBD;
    margin: 16px 0 16px 16px;

    svg {
      fill: #F9A400;
    }
  }

  .preventedForms-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    margin-right: 88px;

    .preventedForms-title {
      color: #0A1551;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      text-align: left;
      width: 100%
    }

    .preventedForms-subtitle {
      color: #343C6A;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .preventedForms-dismiss-button {
    color: #252D5B;
    font-weight: 700;
    font-size: 14px;
    height: 100%;
    padding: 16px;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #E3E5F5;
    transition: .3s;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;

    &:hover {
      background-color: #F3F3FE;
    }
  }
`;
