/* eslint-disable max-len */ import Styled from 'styled-components';
import { FEATURE_NAMES } from '../../../../constants/features';
import { isFeatureEnabled } from '../../../../utils/features/helper';
import { mainFontColor, settingsBgColor } from '../../../../styles/colors';
export const ScPublish = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  background-color: ${settingsBgColor};

  label.title {
    color: ${mainFontColor};
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  p.description {
    font-size: 14px;
    color: #6F76A7;
    margin-bottom: 14px;

    &.noSubElement {
      margin-bottom: 0;
    }
  }
`;
export const ScPublishBody = Styled.div`
  margin-top: ${isFeatureEnabled(FEATURE_NAMES.Embed) ? '0' : '1.5em'};
  padding: 1.25em 1.5em;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.17);
  font-size: 16px;
  line-height: 1.25;
  color: #2c3345;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-bottom: 16px;
`;
export const ScPublishContent = Styled.div`
  max-width: 600px;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  @media screen and (max-width: 480px) {
    padding: 31px 22px;
    margin: 10px 8px;
  }
`;
export const ScRow = Styled.div`
  padding: 25px 25px 28px;

  &:not(:first-child) {
    border-top: 1px solid rgba(228,228,235,.5);
  }
`;
