import Styled from 'styled-components';
const ScNoImage = Styled.div`
  width: 100%;

  svg {
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 98px;
  }

  .altText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
  }
  @media screen and (max-width: 480px) {
    svg {
      max-width: 100%;
      height: 120px;
    }
  }

`;
export default ScNoImage;
