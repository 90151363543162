import Styled from 'styled-components';
export const ScLineHr = Styled.hr`
    border: none;
    border-top: 1px solid #4B5260;
    height: 1px;
    width: 100%;
    margin: 0;
    // A/B Test: ctAppNameIconModal
    .appNameIconSettingsModal & {
        border-top: 0;
        margin-top: 39px;
    }
`;
