import Styled from 'styled-components';
export const ScIMOverlay = Styled.div`
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100%;
  z-index: 1002;
  background-color: rgba(39, 41, 55, 0.61);
  display: flex;
  flex-direction: column;
  align-items: center;

  .a2hs {
    width: 92%;
    max-width: ${({ larger })=>larger ? '730px' : '600px'};
    max-height: 92%;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    margin: auto;
    overflow: auto;
  }
`;
export const ScIMContent = Styled.div`
  height: 100%;
  position: relative;
`;
export const ScIMHeader = Styled.div`
  padding: 24px 24px 0 0;
  display: flex;

  .close-modal {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 auto;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F3F3FE;

    svg {
      width: 30px;
      height: 30px;
      color: #6F76A7;
    }
  }

  .header-content {
    flex: 1 1 auto;
    padding: 0 0 0 27px;
  }

  .announcement-title {
    margin: 12px 0 0;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #2c3345;
  }

  .announcement-description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #8f91aa;
    margin: 0.25em 0;
  }

  .announcement-modal-closeBtn {
    padding: 0;
    height: auto;
    margin: 21px 24px 18px 0;
    display: block;
    border: 0;
    flex: 0 0 auto;
    background: transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: #6F76A7;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px 16px 0 0;
  }

`;
export const ScIMFooter = Styled.div`
  text-align: center;
  
  .bw {
    border-top: 1px solid #DADEF3;
    padding: 16px;
    background-color: #F3F3FE;
  }

  .tw {
    padding: 0 16px 24px 16px;

    ol {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
  }
`;
export const ScIMClose = Styled.button`
  position: absolute;
  right: 0; top: 0;
`;
export const ScImage = Styled.div`
  min-height: 500px;

  .announcementGif {
    opacity: 0;
    width: 100%;
    padding: 28px 34px 47px 28px;
    border-top: 1px solid #eaebf2;
    transition: opacity 0.3s;
  }
  .announcementGif.ready{
    opacity: 1;
  }
`;
