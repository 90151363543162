import Styled from 'styled-components';
export const ScStandaloneContainer = Styled.div`
  width: 100%;
  max-width: 1410px;
  min-height: 200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 0 16px;
  @media screen and (max-width: 880px) {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 88vh;
  }
  @media screen and (max-width: 768px) {
    max-height: 100vh;
  }
  @media screen and (max-width: 480px) {
    padding-bottom: 100px;
  }
`;
export const ScBottomArea = Styled.div`
  width: 100%;
  padding-bottom: 115px;
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
