/* eslint-disable no-confusing-arrow */ /* eslint-disable max-lines */ /* eslint-disable max-len */ import Styled from 'styled-components';
const greenColor = '#78BB07';
const greenColorDarken = '#6da70c';
const blueColor = '#0099FF';
const blueColorDarken = '#0a7cc9';
const navyColor = '#091141';
const agentColor = 'rgba(121, 35, 221, var(--bg-opacity))';
const animMixin = `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  z-index: 0;
`;
export const ScTemplates = Styled.div`
  width: ${({ liteMode, slider })=>liteMode || slider ? '100%' : 'calc(100% - 320px)'};
  @media screen and (max-width: 768px) {
    & {
      width: 100%;
      padding-bottom: 150px;
    }
  }

  .boxes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -20px 20px -20px;
    min-height: 200px;
    @media screen and (max-width: 768px) {
      & {
        margin: 0 -10px;
      }
    }
    @media screen and (max-width: 480px) {
      & {
        /* flex-direction: column; */
        margin: 0;
        /* padding: 0 20px; */
        padding: 0;
        min-width: 240px;
      }
    }
  }

  .resultWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .resultTitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.55;
      color: #0a224c;
      flex-grow: 1;
      padding-right: 16px;
      word-break: break-word;
    }
    @media screen and (max-width: 768px) {
      & {
        margin-bottom: 20px;
        .resultTitle {
          font-size: 18px;
        }
      }
    }
  }

  .noResult {
    max-width: 720px;
    width: 100%;
    margin: 100px auto;
    display: block;
    text-align: center;
    color: ${navyColor};
    svg {
      width: 38px;
      height: 48px;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      font-size: 22px;
      font-weight: normal;
      margin: 20px 0;
    }
    p {
      font-size: 20px;
      line-height: 1.4;
      max-width: 450px;
      text-align: center;
      margin: 0 auto 30px auto;
    }
  }
  //<END> result

  .clearResult {
    border: 0;
    flex-shrink: 1;
    font-size: 16px;
    color: #757a88;
    background-color: #f0f3f7;
    border-radius: 4px;
    font-weight: 400;
    padding: 16px 48px 16px 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 16' width='16' height='16'%3E%3Cpath d='M9.514 8l6.438-6.408a.933.933 0 10-1.32-1.319L8.225 6.711 1.817.273a.933.933 0 00-1.319 1.32L6.936 8.03.498 14.408a.933.933 0 101.32 1.319l6.437-6.438 6.377 6.438a.933.933 0 001.32-1.32L9.514 8z' fill='%23A8AAB5' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px);
    white-space: nowrap;
    word-break: keep-all;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #696e7a;
      background-color: #e9eef3;
    }

    html[dir="rtl"] & {
      padding: 16px 16px 16px 48px;
      background-position: 16px;
    }
    @media screen and (max-width: 768px) {
      background-size: 12px;
      padding: 10px 40px 10px 10px;
    }
  }

  .item {
    &:not(.js-new-card):not(.agent-item) {
      position: relative;
      overflow: hidden;
      width: 33.333%;
      margin-top: 32px;
      margin-bottom: 32px;
      padding: 0 20px;
      @media screen and (max-width: 1080px) {
        & {
          width: 50%;
        }
      }

      @media screen and (max-width: 768px) {
        & {
          margin-top: 0;
          padding: 0 10px;
        }
      }
      @media screen and (max-width: 480px) {
        & {
          width: 100%;
          padding: 0;
          margin: 32px 0;
          &:first-child {
            margin-top: 0;
          }
        }
      }    
    }
    &.agent-item {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    h3,
    .useTemplate,
    .previewCta {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        border-radius: 4px;
        background-color: #f4f5ff;
        transition: all .3s ease;
      }
      &:after {
        content: '';
        ${animMixin}
      }
    }

    h3 {
      position: relative;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      min-height: 24px;
      margin: 16px 0 8px;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all .3s ease;
      &:before,
      &:after {
        width: 50%;
      }
      span {
        color: ${navyColor};
        transition: opacity, filter .3s ease;
        opacity: 0;
      }
      @media screen and (max-width: 768px) {
        & {
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }

    .useTemplate,
    .previewCta {
      pointer-events: none;
      cursor: pointer;
      position: relative;
      border-radius: 4px;
      background: none;
      margin: 8px auto;
      display: block;
      padding: 12px 0;
      width: 100%;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      transition: all .3s ease;
      border: 1px solid transparent;
      span {
        transition: opacity, filter .3s ease;
        opacity: 0;
      }

      @media screen and (max-width: 768px) {
        & {
          font-size: 14px;
          padding: 10px 0;
        }
      }
    }
    .useTemplate {
      span {
        color: ${greenColor};
      }
    }
    .previewCta {
      span {
        color: ${blueColor};
      }
      display: ${({ isDesktop })=>isDesktop ? 'none' : 'block'};
    }

    .image {
      cursor: pointer;
      width: 100%;
      height: auto;
      /* max-height: 300px; */
      position: relative;
      border: 1px solid #C8CEED;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      &:after {
        content: "";
        background: #f4f5ff url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73 80'%3E%3Cpath d='M19.93 76.03c1.5 1.46.44 3.97-1.7 3.97H4.8A4.74 4.74 0 0 1 0 75.34v-13c0-2.09 2.59-3.12 4.1-1.66l15.83 15.35ZM40.9 77.04a10.06 10.06 0 0 1 0-14.3L55.26 48.5a10.22 10.22 0 0 1 14.39 0 10.06 10.06 0 0 1 0 14.3L55.3 77.03a10.22 10.22 0 0 1-14.4 0ZM3.03 41.24a10.06 10.06 0 0 1 0-14.29L27.16 2.96a10.22 10.22 0 0 1 14.39 0 10.06 10.06 0 0 1 0 14.3L17.4 41.23a10.22 10.22 0 0 1-14.38 0ZM22.75 58.34a10.06 10.06 0 0 1 0-14.3l32.6-32.38a10.22 10.22 0 0 1 14.4 0 10.06 10.06 0 0 1 0 14.29l-32.6 32.4a10.22 10.22 0 0 1-14.4 0Z' fill='%23E3E5F5'/%3E%3C/svg%3E") no-repeat center;
        background-size: 40%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      .aspect-ratio {
        width: 100%;
        height: 100%;
      }
      img {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: all .3s ease;
        opacity: 0;
      }
      .animBg {
        ${animMixin}
      }
      .template-hint {
        position: absolute;
        pointer-events: none;
        top: 50%;
        background-color: rgba(10, 21, 81, 0.75);
        border-radius: 40px;
        color: #fff;
        font-size: 16px;
        z-index: 3;
        padding: 11px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        opacity: 0;
        transition: all .3s linear;
      }
    }
    &.image-loaded {
      .image:hover {
        .template-hint {
          transform: translateY(-20px);
          opacity: 1;
        }
      }
    }

    &.approval-item,
    &.table-item {
      .image {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all 0.2s;
          /* background: ${navyColor}; */
          background-color: rgba(9, 17, 65, 0);
          /* opacity: 0; */
          z-index: 2;
          display: ${({ isDesktop })=>isDesktop ? 'block' : 'none'};
        }
        @media screen and (max-width: 550px) {
          & {
            aspect-ratio: 16/18.5;
          }
        }
        @media screen and (max-width: 480px) {
          & {
            aspect-ratio: unset;
          }
        }
      }
    }
    &.pdf-item,
    &.page-item {
      .image {
        max-height: unset;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        .aspect-ratio {
          position: relative;
          width: 100%;
          height: auto;
          pointer-events: none;
          display: block;
        }
        img {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
        .shadows {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          &:before,
          &:after {
            content: "";
            position: absolute;
            left: 0;
            pointer-events: none;
            width: 100%;
            height: 3rem;
            transition: all .3s linear;
          }
          &:before {
            z-index: 2;
            opacity: 0;
            top: 0;
            background: linear-gradient(0, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
          }
          &:after {
            content: "";
            z-index: 1;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
          }
        }
        
        &:hover {
          .shadows {
            &:before {
              opacity: 1;
            }
          }
        }
      }
      &.image-loaded {
        .image {
          img {
            transition: all 1.2s cubic-bezier(.68,-.15,.265,1.2);
          }
        }
      }
    }
    &.app-item {
      .image {
        padding-top: 100%;
        max-height: 630px;
        border: 0;
        transition: border-color .3s ease;
        border-bottom: 1px solid transparent;
        img {
          position: absolute;
          top: 0;
          z-index: 2;
          transition: all 0.2s;
        }
        .animBg {
          z-index: 2
        }
      }
      &.image-loaded {
        .image {
          img {
            transition: all 1.2s cubic-bezier(.68,-.15,.265,1.2);
          }
        }
      }
      .phone-notch {
        left: 6px;
        width: calc(100% - 12px);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        height: 28px;
        background-color: transparent;
        position: absolute;
        color: #FFF;
        top: 2.8%;
        z-index: 3;
        padding: 0 6px;
        font-size: 9px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        white-space: nowrap;
        transition: all .3s linear;
        .l {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 20%;
        }
        .r {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 20%;

          .icons {
            width: 38px;
            height: 8px;
            background-size: 100%;
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 8'%3E%3Cpath d='M12 0h2v8h-2V0ZM10 2H8v6h2V2ZM6 4H4v4h2V4ZM0 6h2v2H0V6ZM20 2h12v4H20V2Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 0a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H20Zm12 1H20a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z' fill='%23fff'/%3E%3Cpath d='M35 2.5a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3Z' fill='%23fff'/%3E%3C/svg%3E");
          }
        }

        &.black {
          color: #000;
          .icons {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 8'%3E%3Cpath d='M12 0h2v8h-2V0ZM10 2H8v6h2V2ZM6 4H4v4h2V4ZM0 6h2v2H0V6ZM20 2h12v4H20V2Z' fill='%23000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 0a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H20Zm12 1H20a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z' fill='%23000'/%3E%3Cpath d='M35 2.5a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3Z' fill='%23000'/%3E%3C/svg%3E");
          }
        }
        &.white {
          color: #FFF;
          .icons {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 8'%3E%3Cpath d='M12 0h2v8h-2V0ZM10 2H8v6h2V2ZM6 4H4v4h2V4ZM0 6h2v2H0V6ZM20 2h12v4H20V2Z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 0a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H20Zm12 1H20a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z' fill='%23fff'/%3E%3Cpath d='M35 2.5a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3Z' fill='%23fff'/%3E%3C/svg%3E");
          }
        }
      }
      .phone-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        path {
          shape-rendering: auto;
          &.splash-path {
            opacity: 0;
            transition: opacity .3s linear;
          }
          &.white-path {
            stroke: white;
            stroke-width: 6px;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          &.inner-border {
            stroke: #c8ceed;
            stroke-width: .5px;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }
    }
    &.approval-item {
      width: 50%;
      .bgColorItem {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .3s linear;
      }
      .image {
        min-height: unset;
        max-height: unset;
        img {
          padding: 8px;
          &.approval-main {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;
            z-index: 1;
          }
          &.approval-blank {
            position: relative;
            width: 100%;
            height: auto;
            z-index: 0;
          }
        }
        @media screen and (max-width: 550px) {
          & {
            aspect-ratio: unset;
          }
        }
      }
      @media screen and (max-width: 480px) {
        & {
          width: 100%;
        }
      }
    }
    &.table-item {
      .bgColorItem {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .3s linear;
      }
      .image {
        min-height: 0;
        img {
          padding-left: 12px;
          padding-top: 12px;
          filter: drop-shadow(4px 2px 4px rgba(0, 0, 0, 0.25));
          &.table-main {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: auto;
            z-index: 1;
          }
          &.table-blank {
            position: relative;
            width: 100%;
            height: auto;
            z-index: 0;
          }
        }
        @media screen and (max-width: 550px) {
          & {
            aspect-ratio: unset;
          }
        }
      }
    }
    &.agent-item {
      .image {
        min-height: 0;
        border: 0;
        border-radius: 8px;
        img {
          &.agent-main {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: auto;
            z-index: 1;
          }
          &.agent-blank {
            position: relative;
            width: 100%;
            height: auto;
            z-index: 0;
          }
        }
        @media screen and (max-width: 550px) {
          & {
            aspect-ratio: unset;
          }
        }
      }
    }
    &.page-item {
      width: 50%;
      .more-templates-area & {
        width: 33.333%;
      }
      .image {
        min-height: unset;
        max-height: unset;
        @media screen and (max-width: 550px) {
          & {
            aspect-ratio: unset;
          }
        }
      }
      @media screen and (max-width: 480px) {
        & {
          width: 100% !important;
        }
      }
    }
    &.image-loaded {
      h3 {
        cursor: pointer;
      }
      h3 span,
      .useTemplate span,
      .previewCta span,
      .image img {
        opacity: 1;
      }
      .animBg,
      h3:after,
      .useTemplate:after,
      .previewCta:after {
        display: none;
      }
      h3:before,
      .useTemplate:before,
      .previewCta:before {
        opacity: 0;
        z-index: -1;
      }
      .useTemplate {
        pointer-events: unset;
        border: 1px solid ${greenColor};
        &:hover {
          background: ${greenColorDarken};
          border-color: ${greenColorDarken};
          span {
            color: #FFF;
          }
        }
        &.useAgent {
          border-color: ${agentColor};
          span {
            color: ${agentColor};
          }
          &:hover {
            background: ${agentColor};
            border-color: ${agentColor};
            span {
              color: #fff;
            }
          }
        }
      }
      .previewCta {
        pointer-events: unset;
        border: 1px solid ${blueColor};
        &:hover {
          background: ${blueColorDarken};
          border-color: ${blueColorDarken};
          span {
            color: #FFF;
          }
        }
      }
    }
    &.approval-item.image-loaded,
    &.table-item.image-loaded {
      .image {
        &:hover {
          box-shadow: ${({ isDesktop })=>isDesktop ? '0 20px 30px rgba(0, 0, 0, 0.15)' : 'none'};
          border-color: #bdbdbd;
          &:before {
            /* opacity: 0.8; */
            background-color: rgba(9, 17, 65, .8);
          }
        }
      }
      .bgColorItem {
        opacity: 1;
      }
    }
    &.app-item.image-loaded {
      .phone-notch,
      .phone-wrapper path.splash-path {
        opacity: 1;
      }
    }
    &.approval-item.image-loaded {
      .image {
        &:hover {
          img {
            transform: scale(1.125);
            @media screen and (max-width: 480px) {
              transform: none;
            }
          }
        }
      }
    }
    &.table-item.image-loaded {
      .image {
        &:hover {
          img {
            transform: scale(1.125);
            @media screen and (max-width: 480px) {
              transform: none;
            }
          }
        }
      }
    }
  }
  .loadMore {
    text-align: center;
    width: 100%;
    margin-bottom: 70px;
    .loadMoreCta {
      cursor: pointer;
      padding: 8px 48px;
      border-radius: 8px;
      background: ${blueColor};
      font-weight: 400;
      color: #fff;
      font-size: 20px;
      line-height: 48px;
      margin: 0;
      border: 1px solid transparent;
      transition: all .2s;

      &:hover {
        background: ${blueColorDarken};
        color: #fff;
      }

      @media screen and (max-width: 768px) {
        & {
          padding: 12px 24px;
          border-radius: 4px;
          margin-top: 0;
          font-size: 16px;
          line-height: 1;
        }
      }
    }
  }

  .categoryBox {
    cursor: pointer;
    background-color: #f9f9f9;
    padding: 10px;
    margin: .5rem;
    border-radius: 4px;
    transition: background-color, box-shadow 0.25s ease;
    min-height: 140px;
    width: calc(33.333% - 1rem);
    @media screen and (max-width: 1024px) {
      & {
        width: calc(50% - 1rem);
      }
    }
    &:hover {
      box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: ${navyColor};
      margin: 16px 0 8px;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      min-height: 70px;
      max-height: 70px;
      overflow: hidden;
      padding: 0;
      font-weight: 400;
      color: #343C6A;
      line-height: 1.6;
      margin: 0 0 9px;
      font-size: 15px;
    }
    .count {
      color: ${navyColor};
      font-size: 14px;
      margin: 6px 0 0;
      display: flex;
      align-items: center;
      svg {
        margin-inline-end: 5px;
      }
    }
  }
  .categoryHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .breadcrumb {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .backButton {
        width: 32px;
        height: 32px;
        background: #E6EBF2;
        border: 0;
        outline: none;
        border-radius: 50%;
        position: relative;
        margin-inline-end: 10px;
        color: #0A1551;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        display: inline-flex;
        &:after {
          content: "/";
          padding: 0 5px;
          margin: 0 5px;
        }
        &:last-child {
          &:after {
            display: none;;
          }
        }
      }
    }
    .heading {
      color: $navyColor;
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin: 10px 0 0 0;
      width: 100%;
    }
    .subheading {
      color: #2B3245;
      font-size: 12px;
      margin: 6px 0 0;
      font-weight: 100;
      display: flex;
      align-items: center;
      svg {
        margin-inline-end: 5px;
      }
    }
    .subBigHeading {
      color: $navyColor;
      line-height: 1.5;
      font-size: 16px;
      margin: 20px 0 10px 0;
    }
    @media screen and (max-width: 768px) {
      & {
        margin-bottom: 20px;
      }
    }
  }

  .see-more {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    padding-top: 16px;

    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      width: 100%;
      border: 0;
      outline: none;
      background-color: transparent;

      .illustration {
        display: block;
        position: relative;
        width: 100%;
        min-height: 240px;
        max-height: 323.98px;
        height: 100vh;
        overflow: hidden;
        border-radius: 4px;
        border: solid 1px #C3CAD8;
        background: #E6EBF2;
        transition: border .2s ease-in-out, background .2s ease-in-out;

        &-content{
          display: block;
          max-width: 312px;
          width: 100%;
          height: 100%;
          position: relative;
          margin: 0 auto;
          opacity: 1;
          transition: opacity .2s;
          @media screen and (min-width: 1200px) and (max-width: 1599px) {
            transform: scale(.8);
          }
          @media screen and (min-width: 992px) and (max-width: 1199px) {
              transform: scale(.5);
          }

          @media screen and (max-width: 991px) and (min-width: 851px){
            transform: scale(.82);
          }

          @media screen and (max-width: 850px) and (min-width: 769px){
            transform: scale(.7);
          }

          .temp {
            position: absolute;
            transform-origin: center;
            width: 160px;
            height: 168px;
            transition: transform .2s ease-in-out, background .2s ease-in-out, opacity .2s ease-in-out;
            background-size: 160px 168px;
            background-repeat: no-repeat;
            background-position: center center;
            top: calc(50% - 84px);
            left: calc(50% - 80px);
          }

          .t1 {
            background-image: url("data:image/svg+xml,%3Csvg width='160' height='168' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23C3CAD8' stroke-width='2' fill='%23FFF' x='1' y='1' width='158' height='166' rx='4'/%3E%3Crect fill='%238894AB' x='16' y='16' width='64' height='4' rx='2'/%3E%3Crect fill='%238894AB' x='16' y='54' width='48' height='4' rx='2'/%3E%3Crect fill='%23D4DAE5' x='30' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23D4DAE5' x='77' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23D4DAE5' x='124' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%238894AB' x='16' y='92' width='64' height='4' rx='2'/%3E%3Crect stroke='%23D4DAE5' stroke-width='2' x='17' y='29' width='126' height='14' rx='2'/%3E%3Crect stroke='%23D4DAE5' stroke-width='2' x='17' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%238894AB' stroke-width='4' x='65' y='72' width='8' height='8' rx='4'/%3E%3Crect stroke='%23D4DAE5' stroke-width='2' x='111' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23D4DAE5' stroke-width='2' x='17' y='109' width='126' height='14' rx='2'/%3E%3Crect fill='%238894AB' x='104' y='136' width='40' height='16' rx='2'/%3E%3C/g%3E%3C/svg%3E");
            transform: scale(.8);
          }
          .t2 {
            background-image: url("data:image/svg+xml,%3Csvg width='160' height='168' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23C71D38' stroke-width='2' fill='%23FFF' x='1' y='1' width='158' height='166' rx='4'/%3E%3Crect fill='%2357647E' x='16' y='16' width='64' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='54' width='48' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='30' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='77' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='124' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='92' width='64' height='4' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='29' width='126' height='14' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23C71D38' stroke-width='4' x='65' y='72' width='8' height='8' rx='4'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='111' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='109' width='126' height='14' rx='2'/%3E%3Crect fill='%23C71D38' x='104' y='136' width='40' height='16' rx='2'/%3E%3C/g%3E%3C/svg%3E");
            opacity: 0;
            transform: scale(.8);
          }
          .t3 {
            background-image: url("data:image/svg+xml,%3Csvg width='160' height='168' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect stroke='%23FBAA32' stroke-width='2' fill='%23FFF' x='1' y='1' width='158' height='166' rx='4'/%3E%3Crect fill='%2357647E' x='16' y='16' width='64' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='54' width='48' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='30' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='77' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='124' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='92' width='64' height='4' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='29' width='126' height='14' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23FBAA32' stroke-width='4' x='65' y='72' width='8' height='8' rx='4'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='111' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='109' width='126' height='14' rx='2'/%3E%3Crect fill='%23FBAA32' x='104' y='136' width='40' height='16' rx='2'/%3E%3C/g%3E%3C/svg%3E");
            opacity: 0;
            transform: scale(.8);
          }

          .gimmicks,
          .gimmicks i {
            position: absolute;
            width: 16px;
            height: 16px;
            top: calc(50% - 8px);
            left: calc(50% - 8px);
            border: none;
            padding: 0;
          }

          .gimmicks i {
            display: block;
            transform-origin: center;
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;
            transform: scale(.5);
            transition: transform .2s ease-in-out, opacity .2s ease-in-out;
          }
        }
        .template-loader{
          transition: opacity .2s;
          opacity: 0;
        }
      }
      span {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 0;
        text-align: center;
        color: $nbText;
        transition: color .2s ease-in-out;
      }
      &.isTouch,
      &:hover {
        span {
          color: #2E69FF;
        }
        .illustration {
          border: solid 1px #C3CAD8;
          background: #F8FBFF;
          &-content{
            .t1 {
              background-image: url("data:image/svg+xml,%3Csvg width='160' height='168' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect stroke='%232E69FF' stroke-width='2' fill='%23FFF' x='1' y='1' width='158' height='166' rx='4'/%3E%3Crect fill='%2357647E' x='16' y='16' width='64' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='54' width='48' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='30' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='77' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%23C3CAD8' x='124' y='74' width='20' height='4' rx='2'/%3E%3Crect fill='%2357647E' x='16' y='92' width='64' height='4' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='29' width='126' height='14' rx='2'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%232E69FF' stroke-width='4' x='65' y='72' width='8' height='8' rx='4'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='111' y='71' width='10' height='10' rx='5'/%3E%3Crect stroke='%23C3CAD8' stroke-width='2' x='17' y='109' width='126' height='14' rx='2'/%3E%3Crect fill='%232E69FF' x='104' y='136' width='40' height='16' rx='2'/%3E%3C/g%3E%3C/svg%3E");
              transform: scale(1);
            }
            .t2 {
              opacity: 1;
              transform: scale(1) rotate(-16deg) translateX(-40px) translateY(16px);
            }

            .t3 {
              opacity: 1;
              transform: scale(1) rotate(16deg) translateX(40px) translateY(-16px);
            }
            .gimmicks {
              i {
                opacity: 1;
              }
              .g1 {
                transform: translateX(-124px) translateY(-56px) scale(.5);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2v4h4c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-4v4c0 1.1046-.8954 2-2 2s-2-.8954-2-2V9.999l-4 .0007c-1.1044.0002-1.9998-.895-2-1.9994V8c0-1.1046.8954-2.0001 2-2.0003l4-.0007V2c0-1.1046.8954-2 2-2s2 .8954 2 2z' fill='%23732EFF' fill-rule='evenodd'/%3E%3C/svg%3E");
              }
              .g2 {
                transform: translateX(-98px) translateY(-88px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0c4.4183 0 8 3.5817 8 8s-3.5817 8-8 8-8-3.5817-8-8 3.5817-8 8-8zm0 4C5.7909 4 4 5.7909 4 8c0 2.2091 1.7909 4 4 4 2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4z' fill='%2301BD6F' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g3 {
                transform: translateX(-34px) translateY(-100px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10c.5128 0 .9355.386.9933.8834L9 11v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 14v-3c0-.5523.4477-1 1-1zm-1.4142-.5858c.3605.3605.3882.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3906.3906-1.0237.3906-1.4142 0-.3605-.3604-.3883-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3905-.3905 1.0237-.3905 1.4142 0zm4.1484-.0832l.0942.0832 2.1213 2.1213c.3906.3906.3906 1.0237 0 1.4142-.3604.3605-.9277.3883-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3905-.3905-.3905-1.0237 0-1.4142.3605-.3605.9277-.3882 1.32-.0832zM14 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L14 9h-3c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L11 7h3zM5 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L5 9H2c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L2 7h3zm7.9497-3.9497c.3605.3604.3883.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3905.3905-1.0237.3905-1.4142 0-.3605-.3605-.3882-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3906-.3906 1.0237-.3906 1.4142 0zM4.3703 2.967l.0942.0832 2.1213 2.1213c.3905.3905.3905 1.0237 0 1.4142-.3605.3605-.9277.3882-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3906-.3906-.3906-1.0237 0-1.4142.3604-.3605.9277-.3883 1.32-.0832zM8 1c.5128 0 .9355.386.9933.8834L9 2v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 5V2c0-.5523.4477-1 1-1z' fill='%23FF6038' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g4 {
                transform: translateX(-8px) translateY(-124px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0031 5.1861L11.0953 8.094l3.283 3.283c.829.829.829 2.1728 0 3.0016-.8288.829-2.1727.829-3.0016 0l-3.283-3.283-2.9077 2.9078c-.8807.8807-2.3086.8807-3.1892 0-.8807-.8806-.8807-2.3085 0-3.1892l2.9085-2.9085-3.2835-3.2825C.8365 3.8381.795 2.591 1.4974 1.757l.1242-.1353c.8289-.8289 2.1728-.829 3.0018-.0002L7.9069 4.904l2.907-2.907c.8807-.8807 2.3086-.8807 3.1892 0 .8807.8806.8807 2.3085 0 3.1891z' fill='%2355D88B' fill-rule='evenodd'/%3E%3C/svg%3E");
              }
              .g5 {
                transform: translateX(88px) translateY(-100px) scale(.5);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0c4.4183 0 8 3.5817 8 8s-3.5817 8-8 8-8-3.5817-8-8 3.5817-8 8-8z' fill='%23504CFF' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g6 {
                transform: translateX(104px) translateY(-128px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2v4h4c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-4v4c0 1.1046-.8954 2-2 2s-2-.8954-2-2V9.999l-4 .0007c-1.1044.0002-1.9998-.895-2-1.9994V8c0-1.1046.8954-2.0001 2-2.0003l4-.0007V2c0-1.1046.8954-2 2-2s2 .8954 2 2z' fill='%237099FF' fill-rule='evenodd'/%3E%3C/svg%3E");
              }
              .g7 {
                transform: translateX(136px) translateY(-96px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10c.5128 0 .9355.386.9933.8834L9 11v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 14v-3c0-.5523.4477-1 1-1zm-1.4142-.5858c.3605.3605.3882.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3906.3906-1.0237.3906-1.4142 0-.3605-.3604-.3883-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3905-.3905 1.0237-.3905 1.4142 0zm4.1484-.0832l.0942.0832 2.1213 2.1213c.3906.3906.3906 1.0237 0 1.4142-.3604.3605-.9277.3883-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3905-.3905-.3905-1.0237 0-1.4142.3605-.3605.9277-.3882 1.32-.0832zM14 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L14 9h-3c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L11 7h3zM5 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L5 9H2c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L2 7h3zm7.9497-3.9497c.3605.3604.3883.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3905.3905-1.0237.3905-1.4142 0-.3605-.3605-.3882-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3906-.3906 1.0237-.3906 1.4142 0zM4.3703 2.967l.0942.0832 2.1213 2.1213c.3905.3905.3905 1.0237 0 1.4142-.3605.3605-.9277.3882-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3906-.3906-.3906-1.0237 0-1.4142.3604-.3605.9277-.3883 1.32-.0832zM8 1c.5128 0 .9355.386.9933.8834L9 2v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 5V2c0-.5523.4477-1 1-1z' fill='%23FBAA32' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g8 {
                transform: translateX(-116px) translateY(116px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0031 5.1861L11.0953 8.094l3.283 3.283c.829.829.829 2.1728 0 3.0016-.8288.829-2.1727.829-3.0016 0l-3.283-3.283-2.9077 2.9078c-.8807.8807-2.3086.8807-3.1892 0-.8807-.8806-.8807-2.3085 0-3.1892l2.9085-2.9085-3.2835-3.2825C.8365 3.8381.795 2.591 1.4974 1.757l.1242-.1353c.8289-.8289 2.1728-.829 3.0018-.0002L7.9069 4.904l2.907-2.907c.8807-.8807 2.3086-.8807 3.1892 0 .8807.8806.8807 2.3085 0 3.1891z' fill='%232E69FF' fill-rule='evenodd'/%3E%3C/svg%3E");
              }
              .g9 {
                transform: translateX(36px) translateY(104px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10c.5128 0 .9355.386.9933.8834L9 11v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 14v-3c0-.5523.4477-1 1-1zm-1.4142-.5858c.3605.3605.3882.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3906.3906-1.0237.3906-1.4142 0-.3605-.3604-.3883-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3905-.3905 1.0237-.3905 1.4142 0zm4.1484-.0832l.0942.0832 2.1213 2.1213c.3906.3906.3906 1.0237 0 1.4142-.3604.3605-.9277.3883-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3905-.3905-.3905-1.0237 0-1.4142.3605-.3605.9277-.3882 1.32-.0832zM14 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L14 9h-3c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L11 7h3zM5 7c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L5 9H2c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L2 7h3zm7.9497-3.9497c.3605.3604.3883.9277.0832 1.32l-.0832.0942-2.1213 2.1213c-.3905.3905-1.0237.3905-1.4142 0-.3605-.3605-.3882-.9277-.0832-1.32l.0832-.0942 2.1213-2.1213c.3906-.3906 1.0237-.3906 1.4142 0zM4.3703 2.967l.0942.0832 2.1213 2.1213c.3905.3905.3905 1.0237 0 1.4142-.3605.3605-.9277.3882-1.32.0832l-.0942-.0832-2.1213-2.1213c-.3906-.3906-.3906-1.0237 0-1.4142.3604-.3605.9277-.3883 1.32-.0832zM8 1c.5128 0 .9355.386.9933.8834L9 2v3c0 .5523-.4477 1-1 1-.5128 0-.9355-.386-.9933-.8834L7 5V2c0-.5523.4477-1 1-1z' fill='%2301BD6F' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g10 {
                transform: translateX(80px) translateY(108px);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0c4.4183 0 8 3.5817 8 8s-3.5817 8-8 8-8-3.5817-8-8 3.5817-8 8-8zm0 4C5.7909 4 4 5.7909 4 8c0 2.2091 1.7909 4 4 4 2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4z' fill='%23FF6038' fill-rule='nonzero'/%3E%3C/svg%3E");
              }
              .g11 {
                transform: translateX(116px) translateY(100px) scale(.5);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2v4h4c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-4v4c0 1.1046-.8954 2-2 2s-2-.8954-2-2V9.999l-4 .0007c-1.1044.0002-1.9998-.895-2-1.9994V8c0-1.1046.8954-2.0001 2-2.0003l4-.0007V2c0-1.1046.8954-2 2-2s2 .8954 2 2z' fill='%23FBAA32' fill-rule='evenodd'/%3E%3C/svg%3E");
              }
            }

          }
        }

      }
    }
  }
  .carousel-title {
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    margin: 40px 20px 10px 20px;
    padding-bottom: 20px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      & {
        margin: 0;
      }
    }
    .title {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 1;
      h2 {
        margin: 0;
        font-size: 24px;
        color: #091141;
        letter-spacing: -.64px;
        line-height: 24px;
        font-weight: 600;
        text-decoration: none;
        margin-inline-end: 10px;
        position: relative;
        span {
          transition: opacity 0s ease 0s, filter 0.3s ease 0s;
          opacity: 0;
        }
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          overflow: hidden;
          border-radius: 4px;
          background-color: #f4f5ff;
          transition: all .3s ease;
        }
        &:after {
          content: '';
          ${animMixin}
        }
      }
      .title-forms-number {
        font-size: 16px;
        color: #dedede;
        transition: opacity 0s ease 0s, filter 0.3s ease 0s;
        opacity: 0;
      }
    }
    .all-link {
      opacity: 0;
      transition: opacity 0s ease 0s, filter 0.3s ease 0s;
      cursor: pointer;
      margin-top: 7px;
      display: flex;
      align-items: center;
      & > span {
        margin-inline-start: 4px;
        font-size: 16px;
        color: #333a50;
        text-decoration: none;
      }

      & > .icon {
        width: 20px;
        height: 20px;
        background: #ebebeb;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        html[dir="rtl"] & {
          transform: rotate(180deg);
        }
      }
    }
    &.loaded {
      .title {
        h2 {
          &:before {
            opacity: 0;
            z-index: -1;
          }
          &:after {
            display: none;
          }
          span {
            opacity: 1;
          }
        }
        .title-forms-number {
          opacity: 1;
        }
      }
      .all-link {
        opacity: 1;
      }
    }
    @media screen and (max-width: 768px) {
      & {
        .title {
          h2 {
            font-size: 18px;
          }
          .title-forms-number {
            font-size: 14px;
          }
        }
      }
    }
    @media screen and (max-width: 550px) {
      & {
        flex-direction: column;
      }
    }
  }
  .backtoTop {
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    background-color: #8894ab;
    color: #ffffff;
    position: fixed;
    z-index: 5;
    bottom: 68px;
    right: -48px;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .2s, right .2s, background-color .2s;
    html[dir="rtl"] & {
      right: inherit;
      left: -48px;
      transition: opacity .2s, left .2s, background-color .2s;
    }
    &.show {
      right: 8px;
      opacity: 1;
      html[dir="rtl"] & {
        right: inherit;
        left: 8px;
      }
    }
    &:hover, &:focus{
      background-color: #6c778c;
    }
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;
