import Styled from 'styled-components';
const ScDevice = Styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 38px;
  border: 1px solid #ccc;
  width: 260px;
  height: 518px;
  padding: 52px 13px;
  margin: 26px auto;
  user-select: none;

  .ap { border: 1px solid #ccc; }


  &::before {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #ccc;
    bottom: 9px;
    left: 50%;
    content: "";
    display: inline-block;
    transform: translateX(-50%);
  }
`;
export default ScDevice;
