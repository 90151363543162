import Styled from 'styled-components';
const FormListWrapper = Styled.div`
  font-size: ${(p)=>p.theme.fontSize};
  font-family: system-ui;
  width: 100%;

  * { box-sizing: border-box; }

  ul { padding: 0; }
  li { list-style: none; }
  li + li { margin-top: 1em; }
`;
export default FormListWrapper;
