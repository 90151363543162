import Styled from 'styled-components';
const ScPageSettingsItem = Styled.li`
  display: flex;
  margin-bottom: 8px;
  user-select: none;
  opacity: ${({ showPageOnNavigation })=>showPageOnNavigation ? '1' : '0.3'};

  .pageSettings-dragHandle {
    display: flex;
    cursor: grab;
    margin-right: 4px;

    svg{
      height: 24px;
      fill: #C8CEED;
      margin-top: 8px;
    }
  }

  .taste {
    background-color: var(--jfv-panel-semidark-gray);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    color: #fff;
    padding: 0;

    svg { width: 17px; }

    .jfIconSVG-wrapper > div {
      width: 17px;
      height: 17px;
    }
  }

  .pageSettings {
    &-icon {
      flex-grow: 0;
      margin-right: 8px;

      .pageSettingsIconBtn {
        display: flex;
        transition: background-color 0.1s ease;
        & > div {
          margin: 0;
          width: 17px;
          height: 17px;
        }

        &.change {
          &:hover {
            background-color: #7F859C;
            fill: #fff;
          }
          svg {
            fill: #fff;
          }
        }

        &.add {
          &:hover {
            background-color: #7F859C;
          }
          svg {
              fill: #BFC3CE;
            }
        }

        svg {
          width: 16px;
        }
      }
    }

    &-input {
      flex-grow: 1;
      height: 40px;

      & > div > div {
        border: none;
      }

      input {
        padding: 10px 8px;
        height: 40px;
        font-size: 14px;
      }
    }
  }

  .pageSettings-input{
    position: relative;

    .page-item-actions{
      position: absolute;
      right: 0;
      top: 50%;
      opacity: 0;
      transform: translateY(-60%);
      transition: all 0.2s ease;
      display: flex;
      align-items: center;

      &.active {
        opacity: 1;
        transform: translateY(-50%);
      }

      .pageSettingsActionButton{
        width: 32px;
        height:32px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
`;
export default ScPageSettingsItem;
