/* eslint-disable max-len */ import Styled from 'styled-components';
export const ScThemeSelector = Styled.div`
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:first-child {
        margin-inline-end: 10px;
      }
    }
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border: 0;
      outline: none;
      background-color: transparent;
      .themeIcon {
        width: 64px;
        height: 64px;
        margin: 0 0 8px;
        background: #E3E5F5;
        border-radius: 4px;
        transition: border .2s ease-in-out;
        border: solid 2px #E3E5F5;
        position: relative;
        overflow: hidden;
        svg {
          display: block;
          position: absolute;
          top: -2px;
          left: -2px;
          width: 64px;
          height: 64px;
          transform-origin: center;
          transition: transform .1s ease-out;
          .anim {
            transition-duration: .1s;
            transition-timing-function: ease-out;
          }
        }
      }
      span {
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: #091141;
        width: 64px;
        font-size: 12px;
        line-height: 16px;
        transition: color .2s ease-in-out;
      }
      &:hover {
        .themeIcon {
          border: solid 2px #FFB629;
        }
      } 
      &:hover,
      &.themeActive {
        .themeIcon {
          border: solid 2px #FF6100;
          svg {
            transform: scale(1.25);
          }
        }
        span {
          color: #FF6100;
        }
      }
      &.themeClassic {
        .anim {
          transition: opacity, width;
        }
        .blue {
          transition-delay: 0, .1s;
        }
        .orange {
          transition-delay: .1s, .2s;
        }
        .yellow {
          transition-delay: .2s, .3s;
        }
        &:hover .anim,
        &.themeActive .anim {
          opacity: 1;
          width: 16px;
        }
      }
      &.themeCard {
        .blue {
          transition-delay: 0, .1s;
          transition: opacity, width;
        }
        .orangeTop {
          transition-delay: .1s, .2s;
          transition: opacity, width;
        }
        .orangeBottom {
          transition-delay: .2s, .3s, .3s;
          transition: opacity, width, transform;
        }
        .yellow {
          transition-delay: .3s, .4s, .4s;
          transition: opacity, width, transform;
        }
        &:hover,
        &.themeActive {
          .blue, .orangeTop {
            opacity: 1;
            width: 14px;
          }
          .orangeBottom, .yellow {
            opacity: 1;
            width: 14px;
            transform: translateX(-11px);
          }
        }
      }
    }
  }
`;
