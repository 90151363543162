import Styled from 'styled-components';
export const ScCircleLoader = Styled.div`
  position: absolute !important;
  width: 100% !important;
  height: 100px !important;
  top: 48%;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .loadingText {
    color: ${({ isDonationApp })=>isDonationApp ? '#0099FF' : '#78BB07'};
    margin-top: 20px;
  }
`;
export const CircleLoaderSvg = Styled.svg`
width: 100%;
height: 100%;
.circle {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 10px;
  stroke-dasharray: 140px;
  stroke-dashoffset: 139px;
  transform: rotate(180deg);
}
.c1 {
  animation-name: circleAnim1, color;
  animation-fill-mode: backwards;
  animation-duration: 1.5s, 6s;
  transform-origin: center;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.21, 0.09, 0.48, 0.65), ease-out;
}

@keyframes circleAnim1 {
  0% { stroke-dashoffset: 139px; }
  50% {
    stroke-dashoffset: 30px;
    transform: rotate(400deg);
  }
  100% {
    stroke-dashoffset: 140px;
    transform: rotate(900deg);
  }
}
@keyframes color {
  0% { stroke: #0099FF; }
  100% { stroke: #0099FF; }
}
`;
