import Styled from 'styled-components';
import { ScRB } from '@jotforminc/modals';
export const ScStillEditingModal = Styled(ScRB)`
.title {
  margin-bottom: 22px;
  font-size: 22px;
  font-weight: 700;
}

.custom-content {
  margin-bottom: unset;
  color: #0A1551;
  font-weight: 400;

  &-second-line {
    margin-top: 30px;
  }
}
`;
