import Styled from 'styled-components';
const ScActionButtons = Styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
  display: flex;
  gap: 12px;

  .firstBtn {
    ${({ buttonBgColor })=>buttonBgColor && `
      background: ${buttonBgColor};
      border-color: ${buttonBgColor};
    `}
  
    ${({ buttonBgHoverColor })=>buttonBgHoverColor && `
      &:hover {
        background: ${buttonBgHoverColor};
      }
    `}
  }
`;
export default ScActionButtons;
