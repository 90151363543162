import Styled from 'styled-components';
import { mainFontColor } from '../../../styles/colors';
import ScToast from '../ScToast';
const ScShoppingNotification = Styled(ScToast)`
  background-color: #fff;
  flex-wrap: wrap;

  .title {
    display: flex;
    flex: 0 0 100%;
    font-weight: 700;

    .message {
      ${({ color })=>{
    switch(color){
        case 'green':
            return 'color: #78BB07;';
        case 'red':
            return 'color: #FC0000;';
        case 'pink':
            return 'color: #FF68B0;';
        case 'grey':
            return 'color: #6F76A7;';
        default:
            return 'color: #6F76A7';
    }
}}
    }

    .icon {
      flex: 0 0 auto;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .product {
    display: flex;
    flex: 0 0 100%;
    color: ${mainFontColor};
    margin-top: 13px;

    .simple-product-item {
      display: flex;
    }

    .product-image {
      display: flex;
      border: 1px solid #D3DCEF;
      border-radius: 4px;
      flex: 0 0 auto;
      margin-right: 16px;

      img {
        width: 90px;
        height: 48px;
        border-radius: 3px;
      }
    }

    .product-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;

      &-name {
        flex: 1 1 100%;
      }

      &-amount {
        flex: 1 1 100%;
        text-transform: capitalize;
      }
    }

    .info {
      flex: 1 1 100%;
    }
  }
`;
export default ScShoppingNotification;
