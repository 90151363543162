import styled from 'styled-components';
import { ScRightPanelBlock } from '../../../../../styles/scRightPanel';
export const ScIconSelector = styled.div`
  ${ScRightPanelBlock} {
    padding-left: 0;

    & + ${ScRightPanelBlock} {
      padding-right: 0;
    }
  }
`;
