import Styled from 'styled-components';
const ScRoles = Styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  grid-template-columns: repeat(auto-fit, 116px);
  justify-items: center;

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, 154px);
    grid-column-gap: 4px;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;
export default ScRoles;
