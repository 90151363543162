import Styled, { css } from 'styled-components';
export const ScTextArea = Styled.div`
  position: relative;

  .descriptionTextArea {
    resize: none;
    outline: none;
    ${({ theme })=>{
    switch(theme){
        case 'light':
            return css`
          box-shadow: 0 0 0px 1px #d8dae9;
          background-color: #fff;
          color: #2c3345;
        `;
        case 'dark':
        default:
            return css`
          box-shadow: 0 0 0px 1px #303339;
          background-color: var(--jfv-panel-right-item-background);
          color: #fff;

          &::placeholder {
            color: #AEB0B8;
          }
        `;
    }
}}
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    font-family: inherit;

    &[maxLength="240"]{
      min-height: 145px;
    }

    & ~ span, .limit {
      position: absolute;
      bottom: 10px;
      right: 8px;
      color: #a9a9af;
      font-size: 12px;
    }
  }
`;
