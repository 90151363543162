import Styled, { css } from 'styled-components';
const dimension = '44px';
const largeDimension = '100px';
export const ScItemIcon = Styled.div`
  min-width: ${dimension};
  height: ${dimension};
  margin: 0 8px;
  border-radius: 4px;
  display: flex;
  align-self: center;
  pointer-events: none;
  ${({ backgroundColor })=>backgroundColor ? `
    background-color: ${backgroundColor};
  ` : ''}
  position: relative;

  & > div {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      ${({ iconColor })=>iconColor ? `
        fill: ${iconColor};
      ` : ''}
    }

    img {
      width: 100%;
    }
  }

  ${({ elementSize })=>elementSize === 'large' && css`
  min-width: ${largeDimension};
  height: ${largeDimension};

  & > div {
    width: 68px;
    height: 68px;
  }
  `};

  ${({ itemTextAlignment })=>{
    switch(itemTextAlignment){
        case 'left':
            return 'margin: 0 16px 0 0;';
        case 'center':
            return 'margin: 0 0 8px 0;';
        case 'right':
            return 'margin: 0 0 0 16px;';
        default:
            return '';
    }
}}
`;
