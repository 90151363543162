import Styled, { css } from 'styled-components';
import { FEATURE_NAMES } from '../constants/features';
import { isFeatureEnabled } from '../utils/features/helper';
import { settingsBgColor } from './colors';
export const ScContainer = Styled.div`
  width: 100%;
  margin: 0 auto;

  &.forBuilder { padding: 3.5em 0; max-width: 720px; }
  ${isFeatureEnabled(FEATURE_NAMES.Embed) ? css`
  ` : css`
    &.forShare { padding: 3.5em 0; max-width: 600px; }
  `};


  & .disabledForGuest{
      opacity: .5;

      & * {
       pointer-events: none;
      }
    }
  }

  &.forSelect {
    padding: 1.5em 0;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
  }
  &.forPreview { height: 100%; overflow-y: scroll; overflow-x: auto; }

  padding: 0;
  }
  @media screen and (max-width: 480px) {
    &.forBuilder { padding: 1.5em 0; }
    &.forSelect { padding: 1em 0 1.5em; }
    &.forPreview { padding: 1em 0 1.5em; }
  }
`;
export const appIconBox = css`
  border-radius: 4px;

  img {
    display: block;
    width: 100%;
    object-fit: contain;
    ${isFeatureEnabled(FEATURE_NAMES.AppLogoSize) ? `
    max-width: unset;
    max-height: unset;
    ` : `
    max-width: 80px;
    max-height: 80px;
    `}
  }
`;
export const ScBuilderWrapper = Styled.div`
  height: 100%;
  padding-bottom: ${({ isPreviewMode })=>isPreviewMode ? '70px' : '44px'};
  background-color: ${settingsBgColor};
`;
